import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { Button } from "reactstrap";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { filterIcon, plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import SkillFilter from "./filter";
import SkillView from "./table";
export const SkillAPI = (data: any) => {
  return { ...useApi("admin/skill", true, data, {}, false, true) };
};
function SkillList(props: any) {
  const [filter, setFilter] = useState(true);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedProfession, setSelectedProfession ] = useState([]);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");

  const payload = {
    pagination: 1,
    countryId: selectedValue,
    status:status,
    isActive: status,
    industryId: selectedIndustry,
    professionId: selectedProfession,
  };
  const handleFilterToggle = () => {
    setFilter(!filter);
  };
  const app = useApp();

  useEffect(() => {
    app.updateConversation("Skill");
  }, []);

  return (
    <PageLayout
      title="Skill List"
      item={
        <>
          <SearchData getSearchedData={setSearch} />
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <SkillFilter
            setSelectedValue={setSelectedValue}
            setSelectedIndustry={setSelectedIndustry}
            handleFilterToggle={handleFilterToggle}
            selectedIndustry={selectedIndustry}
            selectedValue={selectedValue}
            status={status}
            setStatus={setStatus}
            setSelectedProfession ={setSelectedProfession }
            selectedProfession={selectedProfession}
          />
        )
      }
      View={
        <SkillView
          selectedValue={selectedProfession}
          payload={payload}
          search={search}
        />
      }
    />
  );
}

export default SkillList;
