import moment from "moment";
export const getDate = (value: any) => {
  try {
    if (value != undefined && value != null) {
      return new Date(value);
    }
  } catch (error) {
    console.log("dateError", error);
  }
  return undefined;
};

export const formatDateToDDMMYYYY = (date: any) => {
  return moment(date).format("DD-MM-YYYY");
};

export const formatDateToYYYYMMDD = (date: string | Date): string => {
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};
