import React, { useEffect } from "react";
import { CityAPI } from "./list";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";

export default function CityView(props: any) {
  const { selectedValue, payload, status, search } = props;
  const api: any =
    selectedValue?.length > 0 || search
      ? CityAPI({ ...payload, searchText: search })
      : [];
  // useEffect(() => {
  //   api?.mutate();
  // }, [api && status]);
  return (
    <>
      <DataView
        columns={columns({ api })}
        rows={api?.data}
        updatePagination={api.updatePagination}
      />
    </>
  );
}
