import React, { useState } from "react";
import {
  Table,
  useTable,
  ActionType,
  SortingMode,
  PagingPosition,
  ITableProps,
  kaReducer,
} from "ka-table";
import { updatePageSize } from "ka-table/actionCreators";
import { Col, FormGroup, Input, Row } from "reactstrap";
import {
  ExperienceIcon,
  country,
  date,
  industry,
  keyConcept,
  profession,
  skill,
  topic,
} from "../../constant/icons";
import { TableColumn, generateUniqueId } from "./table";
import { ChildComponents } from "ka-table/models";
import PagingPages from "./pagingPages";
import { formatDateToDDMMYYYY } from "../../helper/utility";
import { Tooltip } from "react-tooltip";
import { useFilterHelper } from "../../helper/filterHelper";
import { DispatchFunc } from "ka-table/types";

const DetailsRow: React.FC = ({ rowData }: any) => {
  return (
    <div>
      <Row className="m-0">
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="countryName">
            <span>{country.icon}</span>
            <p>{rowData.countryName}</p>
          </div>
          <Tooltip anchorSelect="#countryName" place="bottom">
            Country
          </Tooltip>
        </Col>
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="expYears">
            <span>{ExperienceIcon.icon}</span>
            <p>{rowData.expYears} year</p>
          </div>
          <Tooltip anchorSelect="#expYears" place="bottom">
            Experience
          </Tooltip>
        </Col>
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="industryName">
            <span>{industry.icon}</span>
            <p>{rowData.industryName}</p>
          </div>
          <Tooltip anchorSelect="#industryName" place="bottom">
            Industry
          </Tooltip>
        </Col>
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="skillName">
            <span>{skill.icon}</span>
            <p>{rowData.skillName}</p>
          </div>
          <Tooltip anchorSelect="#skillName" place="bottom">
            Skill
          </Tooltip>
        </Col>
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="professionName">
            <span>{profession.icon}</span>
            <p>{rowData.professionName}</p>
          </div>
          <Tooltip anchorSelect="#professionName" place="bottom">
            Profession
          </Tooltip>
        </Col>
        <Col className="px-2 mb-3">
          <div className="question_details_col" id="createdAt">
            <span>{date.icon}</span>
            <p>{formatDateToDDMMYYYY(rowData.createdAt)}</p>
          </div>
          <Tooltip anchorSelect="#createdAt" place="bottom">
            Created Date
          </Tooltip>
        </Col>
      </Row>
      <Row className="m-0">
        {rowData.topicName && (
          <Col className="px-2">
            <div className="question_details_col" id="Topic">
              <span>{topic.icon}</span>
              <p>{rowData.topicName}</p>
            </div>
            <Tooltip anchorSelect="#Topic" place="bottom">
              Topic
            </Tooltip>
          </Col>
        )}
        {rowData.keyConceptName?.lenght > 0 && (
          <Col className="px-2">
            <div className="question_details_col" id="keyConceptName">
              <span>{keyConcept.icon}</span>
              <p>{rowData.keyConceptName}</p>
            </div>
            <Tooltip anchorSelect="#keyConceptName" place="bottom">
              Key Concept
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  );
};

const GetComponents = (
  columnsList?: TableColumn[],
  openDetailsRow?: any,
  setOpenDetailsRow?: any
): ChildComponents | undefined => {
  if (columnsList && columnsList.length > 0) {
    const componentList = columnsList.filter(
      (x) => x.component || x.valueConvert
    );
    if (componentList && componentList.length > 0) {
      return {
        cellText: {
          content: (props) => {
            const columnFind = componentList.find(
              (x) => x.key === props.column.key
            );
            if (columnFind) {
              if (columnFind.component) {
                return (
                  <>
                    <columnFind.component
                      openDetailsRow={openDetailsRow}
                      setOpenDetailsRow={setOpenDetailsRow}
                      {...props}
                    />
                  </>
                );
              } else if (columnFind.valueConvert) {
                const value = columnFind.valueConvert(
                  props.rowData[props.column.key],
                  props.rowData
                );
                return value;
              }
            }
          },
        },
      };
    }
  }
  return undefined;
};

let tableComponents: ChildComponents = {
  detailsRow: {
    elementAttributes: () => ({
      style: {
        backgroundColor: "#eee",
      },
    }),
    content: DetailsRow,
  },
  table: {
    elementAttributes: () => ({
      className: "table table-striped table-hover",
    }),
  },
  noDataRow: {
    content: () => {
      return "No Data Found";
    },
  },
  pagingIndex: {
    elementAttributes: ({ isActive }) => ({
      className: `page-item ${isActive ? "active" : ""}`,
    }),
    content: ({ text, isActive }) => (
      <button className={`page-link ${isActive ? "active" : ""}`}>
        {text}
      </button>
    ),
  },
  pagingPages: {
    elementAttributes: () => ({
      className: "pagination",
    }),
    content(props) {
      return (
        <>
          {" "}
          <PagingPages {...props} />
        </>
      );
    },
  },
  pagingSizes: {
    content: (props) => {
      return (
        <>
          <FormGroup>
            <Input
              type="select"
              value={props.pageSize}
              onChange={(e) => {
                props.dispatch(updatePageSize(Number(e.target.value)));
              }}
            >
              {props.pageSizes?.map((size) => (
                <option key={size}>{size}</option>
              ))}
            </Input>
          </FormGroup>
        </>
      );
    },
  },
};

export const AccordionTable = (props: any) => {
  const { columns, rows } = props;
  const [openDetailsRow, setOpenDetailsRow] = useState(-1);
  const components = GetComponents(columns, openDetailsRow, setOpenDetailsRow);
  const paging = props.updatePagination
    ? {
        paging: {
          enabled: true,
          pageIndex: rows?.currentPage ? rows?.currentPage - 1 : 0,
          pagesCount: rows?.totalPages ? rows.totalPages : 1,
          pageSizes: [10, 20, 50, 100],
          pageSize: props.updatePagination.pagination.page,
          position: PagingPosition.Bottom,
        },
      }
    : {};
  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        props.updatePagination?.setPagination({
          ...props.updatePagination?.pagination,
          page: action.pageIndex,
        });
      } else if (action.type === ActionType.UpdatePageSize) {
        props.updatePagination?.setPagination({
          ...props.updatePagination?.pagination,
          limit: action.pageSize,
        });
      }
    },
  });
  if (components) {
    tableComponents = { ...tableComponents, ...components };
  }
  const [option, changeOptions] = useState<any>({
    columns: columns,
    detailsRows: [],
    rowKeyField: "id",
  });
  const dispatch: DispatchFunc = (action) => {
    changeOptions((prevState: ITableProps) => kaReducer(prevState, action));
  };
  return (
    <>
      {" "}
      <Table
        key={generateUniqueId()}
        table={table}
        dispatch={dispatch}
        data={rows?.list}
        {...((rows?.list?.length > 9 || rows?.totalPages > 1) && { ...paging })}
        childComponents={tableComponents}
        {...option}
        rowKeyField={"id"}
        sortingMode={SortingMode.Single}
      />
    </>
  );
};
