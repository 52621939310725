import { DataType } from "ka-table/enums";
import { TableColumn } from "../../components/table/table";
import { StatusControl } from "../../components/buttons/status";
import RatioKey from "./ratio";

export const columns = (actions: any): TableColumn[] => [
  {
    key: "id",
    title: "id",
    dataType: DataType.String,
    visible: false,
  },
  {
    key: "conceptName",
    title: "Key Concept",
    component: RatioKey,
  }, {
    key: "skillName",
    title: "skill",
    dataType: DataType.String,
  },
  {
    key: "professionName",
    title: "Profession",
    dataType: DataType.String,
  },
 
  {
    key: "industryName",
    title: "Industry",
    dataType: DataType.String,
  },
  {
    key: "countryName",
    title: "Country",
    dataType: DataType.String,
  },

  {
    key: "isActive",
    title: "Status",
    dataType: DataType.Boolean,
    component: StatusControl,
    callBack: (row) => {
      actions.api.status({
        id: row.id,
        isActive: !row.isActive,
      });
    },
    style: {
      textAlign: "center",
    },
  },
  // {
  //   key: "edit",
  //   title: "",
  //   component: EditControl,
  //   callBack: (row) => {
  //     actions.openAddEditFrom(row);
  //   },
  //   style: {
  //     textAlign: "center",
  //   },
  // },
];
