import AppMainLogo from "../../assets/images/login_bg_05.jpg";
import ResetPasswordImage from "../../assets/images/login_bg_05.jpg";
import ForgotPasswordImage from "../../assets/images/login_bg_05.jpg";

const LogInTitle = "sign in";
const LogInSubTitle = "Enter your login credentials to access your account.";

const ResetPasswordTitle = "reset password";
const ResetPasswordSubTitle =
  "Set the new password for your account so you can login.";

const ForgotPasswordTitle = "forgot password";
const ForgotPasswordSubTitle =
  "Enter your email address below and we'll send you password reset link.";

export {
  AppMainLogo,
  ResetPasswordImage,
  ForgotPasswordTitle,
  ForgotPasswordSubTitle,
  ForgotPasswordImage,
  LogInTitle,
  LogInSubTitle,
  ResetPasswordTitle,
  ResetPasswordSubTitle,
};
