import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { close, searchIcon } from "../../constant/icons"
export default function SearchData(props: any) {
  const [search, setSearch] = useState<string>("");
  return (
    <div className="search_box">
      <Button
        color="link"
        className="p-0"
        onClick={() => {
          props.getSearchedData(search);
        }}
      >
        {searchIcon.icon}
      </Button>
      {search && (
        <Button
          color="link"
          className="p-0 close-btn"
          onClick={() => {
            setSearch("");
            props.getSearchedData("");
          }}
        >{close.icon}</Button>
      )}
      <Input
        type="text"
        name="searchbox"
        onChange={(e) => {
          setSearch(e.target.value);
          if (e.target.value?.length <= 0) {
            props.getSearchedData("");
            setSearch("");
          }
        }}
        onKeyDown={(e) => {

          if (e.key === 'Enter') {
            const value = e.currentTarget.value;
            props.getSearchedData(value);
            setSearch(value);
          }
        }}
        placeholder="Search box"
        value={search}
      />
    </div>
  );
}
