import { FormGroup, Input, Label } from "reactstrap";
import { confirmBox } from "../toast";

const StatusControl = (props: any) => {
  const { disabled, column, rowData, value, message } = props;
  return (
    rowData?.role?.roleName !== "Super Admin" && (
      <FormGroup switch className="status_switch">
        <Label check className={value ? "active" : "inactive"}>
          <Input
            type="switch"
            disabled={disabled || false}
            defaultChecked={value}
            onClick={async () => {
              const response = await confirmBox(message);
              if (response && column.callBack) {
                column.callBack(rowData);
              }
            }}
          />
          <span>{value ? "Active" : "Deactive"}</span>
        </Label>
      </FormGroup>
    )
  );
};

export { StatusControl };
