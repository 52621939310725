import { Badge } from "reactstrap";

export default function RatioKey(props: any) {
  const { disabled, column, rowData, value, message } = props;
  return (
    <div className="key_concept">
      {rowData?.conceptName} &nbsp;
      <Badge>{rowData?.ratio ? rowData?.ratio : 0}</Badge>
    </div>
  );
}
