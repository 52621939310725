import { DataType } from "ka-table/enums";
import { TableColumn, generateUniqueId } from "../../components/table/table";
import { InfoAcco, ViewAccordion } from "../../components/buttons/info";
import { questionscore } from "./questionlist";

export const columns = (actions: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.String,
      visible: false,
    },
    {
      key: "questionBody",
      title: "question",
      dataType: DataType.Object,
      component: questionscore,
    },
    {
      key: "table-action",
      title: "",
      component:actions.questionType === "Review" ? ViewAccordion : InfoAcco,
      callBack: (row) => {
        if (row.status) {
          const moveToQue = {
            id: row.id,
            countryId: row.countryId,
            experienceId: row.experienceId,
            industryId: row.industryId,
            keyConceptId: row.keyConceptId,
            professionId: row.professionId,
            skillId: row.skillId,
            topicId: row.topicId,
            countryName: row.countryName,
            expYears: row.expYears,
            industryName: row.industryName,
            keyConceptName: row.keyConceptName,
            professionName: row.professionName,
            skillName: row.skillName,
            topicName: row.topicName,
            complexity: row.complexity,
            question: row.question,
            isActive: true, 
            score:row.score
          }
          actions.api.create(moveToQue, true, "/admin/question ")
        }
        else {
          actions.api.removeValue(`?questionId=${row.id}`);
        }
      },
      style: {
        textAlign: "center",
      },
    }

  ];
};
