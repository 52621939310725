export const SUCCESS = "success";
export const DANGER = "error";
export const SUPER_ADMIN = "Super Admin";
export const ADMIN = "Admin";
export const REVIEWER = "Reviewer";
export const status = [
  // {
  //   status: "All",
  //   isActive: "",
  // },
  {
    status: "Active",
    isActive: true,
  },
  {
    status: "Deactive",
    isActive: false,
  },
];
