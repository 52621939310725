import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
interface currentApp {
    appCode: string,
    functionList: any[] | undefined,
    payloadMapGroup: any[] | undefined
}

interface AppContextValue {

    conversation: any,
    updateConversation: (currentConversation: any) => void;
   }

const AppContext = createContext<AppContextValue>({

    conversation: null,
    updateConversation: (currentConversation: any) => { },
});


export const AppProvider = ({ children }: any) => {
    const [conversation, setConversation] = useState<any>(null)
    let updateConversation = (currentConversation: any) => {
        setConversation(currentConversation)
    }
    return (
        <AppContext.Provider value={{   updateConversation, conversation, }}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    return useContext(AppContext)
}
