import { Nav } from "reactstrap";
import { AppLogo } from "../../constant/logo";
import { Link } from "react-router-dom";
import { GetMenuList } from "../../constant/menus";
export const Aside = (props: any) => {
  const { isOpen } = props;
  let MenuList = GetMenuList();
  return (
    <aside className={isOpen ? "sidenav collapse Active" : "sidenav collapse "}>
      <div className="sidenav-logo mb-4">
        <Link to={"/dashboard"}>
          {AppLogo.main}
          {AppLogo.small}
        </Link>
      </div>
      <div className="nav_scroll">
        <Nav vertical className="nav-main small-scroll">
          {MenuList}
        </Nav>
      </div>
    </aside>
  );
};
