import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DashboardIcon,
  MasterIcon,
  city,
  country,
  designation,
  industry,
  job,
  keyConcept,
  profession,
  question_menu,
  skill,
  state,
  topic,
  users,
} from "./icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN, REVIEWER, SUPER_ADMIN } from "./commonConstants";
import React, { useEffect, useState } from "react";
interface Menu {
  name: string;
  route: string;
  icon: any;
  menuShow: boolean;
  childData: any[];
}

export const RoleWiseModule = (
  SUPER_ADMIN: string | null,
  ADMIN: string | null,
  REVIEWER: string | null
) => {
  let latestRoleName = null;
  const roleName = localStorage.getItem("roleName");
  if (roleName) {
    try {
      const parsedRoleName = JSON.parse(roleName);
      latestRoleName = parsedRoleName;
    } catch (error) {
      // Handle parsing error
    }
  }
  if (latestRoleName !== null) {
    return (
      SUPER_ADMIN === latestRoleName ||
      ADMIN === latestRoleName ||
      REVIEWER === latestRoleName
    );
  }

  return false; // Default value if the role name is not available yet
};
export const GetMenuList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [route, setRoute] = useState(null);
  let MenuList: Menu[] = [
    {
      name: "Dashboard",
      route: "dashboard",
      icon: DashboardIcon.icon,
      menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
      childData: [],
    },

    {
      name: "User master",
      route: "user",
      icon: users.icon,
      menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
      childData: [],
    },
    {
      name: "Master",
      route: "",
      icon: MasterIcon.icon,
      menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
      childData: [
        {
          name: "Country",
          route: "country",
          icon: country.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "State",
          route: "state",
          icon: state.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "City",
          route: "city",
          icon: city.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },

        {
          name: "Industry ",
          route: "industry",
          icon: industry.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "Profession ",
          route: "profession",
          icon: profession.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "Job ",
          route: "job",
          icon: job.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },

        {
          name: "Skill ",
          route: "skill",
          icon: skill.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "Designation ",
          route: "designation",
          icon: designation.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "Key-concept ",
          route: "key-concept",
          icon: keyConcept.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
        {
          name: "Topic ",
          route: "topic",
          icon: topic.icon,
          menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, null),
        },
      ],
    },
    {
      name: "Question ",
      route: "question",
      icon: question_menu.icon,
      menuShow: RoleWiseModule(SUPER_ADMIN, ADMIN, REVIEWER),
      childData: [],
    },
  ];
  useEffect(() => {
    if (MenuList) {
      MenuList = MenuList;
    }
  }, []);
  return MenuList.filter((item) => item.menuShow === true).map(
    (item, index) => {
      let isActivePath =
        location.pathname === `/${item.route}` ||
        (location.pathname === "/" && item.route === "conversation")
          ? "active"
          : "";

      if (location.pathname === "/question/view" && item.route === "question") {
        isActivePath = "active";
      }
      let isMaster = location.pathname === `/${route}` ? "active" : "";
      return (
        <React.Fragment key={index}>
          {item.childData.length > 0 ? (
            <UncontrolledDropdown nav inNavbar direction="end">
              <DropdownToggle nav className={isMaster} caret>
                <span>{MasterIcon.icon}</span>
                Master
              </DropdownToggle>
              <DropdownMenu className="small-scroll" end>
                {item.childData.map((data, id) => {
                  let isActive =
                    location.pathname === `/${data.route}` ||
                    location.pathname === "/"
                      ? "active"
                      : "";

                  return (
                    <DropdownItem
                      key={id}
                      onClick={() => {
                        navigate(`/${data.route}`);
                        setRoute(data.route);
                      }}
                      className={isActive}
                    >
                      <span>{data.icon}</span>
                      {data.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <NavItem key={index}>
              <NavLink
                onClick={() => {
                  navigate(`/${item.route}`);
                }}
                className={isActivePath}
              >
                {item.icon && <span>{item.icon}</span>}
                {item.name}
              </NavLink>
            </NavItem>
          )}
        </React.Fragment>
      );
    }
  );
};
