import React, { useEffect, useState } from "react";
import { StateAPI } from "../../modules/state/list";
import ReactSelect from "react-select";
import { Label } from "reactstrap";
import { IndustryAPI } from "../../modules/industry/list";
import { JobAPI } from "../../modules/Job/list";
import { SkillAPI } from "../../modules/skill/list";
import { ProfessionAPI } from "../../modules/profession/list";
import { KeyAPI } from "../../modules/concept/list";
import { useApi } from "../../helper/apiHook";
import { status } from "../../constant/commonConstants";
import { DesignationAPI } from "../../modules/designation/list";

export const CountryAPI = (data: {}) => {
  return { ...useApi("admin/country", true, data, {}, false, true) };
};
export function CountryFilter(props: any) {
  const [search, setSearch] = useState("");
  const country = CountryAPI({ pagination: 0 });
  const ListOfRole = country?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.countryName,
    }));

  useEffect(() => {
    country?.getSearchedData("");
  }, [props?.selectedValue?.length === 0]);
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        isClearable={true}
        name="country"
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            country?.getSearchedData(newValue);
          }
        }}
        onChange={(newValue: any) => {
          if (newValue) {
            props.setSelectedValue([newValue.value]);
          } else {
            props.setSelectedValue([]);
            setSearch("");
            country?.getSearchedData("");
          }
        }}
        value={ListOfRole?.filter((option: any) =>
          props?.selectedValue?.includes(option.value)
        )}
        options={ListOfRole}
      />
      <Label>Country</Label>
    </div>
  );
}

export function StatusFilter(props: any) {
  const statusData = status?.map((item: any) => ({
    value: item.isActive,
    label: item.status,
  }));
  return (
    <div>
      <div className="form-floating sticky_label mb-0">
        <ReactSelect
          name="status"
          isClearable={true}
          onChange={(newValue: any) => {
            if (newValue) {
              props.setStatus(newValue.value);
            } else {
              props.setStatus("");
            }
          }}
          options={statusData}
          value={statusData.filter((d) => d.value === props.status && d.label)}
        />
        <Label>Status</Label>
      </div>
    </div>
  );
}

export function StateFilter(props: any) {
  const { selectedData, search, setSearch } = props;
  let state: any = null;
  if (selectedData?.length > 0) {
    state = StateAPI({
      ...props.filterBody,
      pagination: search?.length > 0 ? 0 : 1,
    });
  }
  const stateList = state?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.stateName,
    }));
  if (selectedData?.length === 0 && props?.selectedState?.length > 0) {
    setSearch([]);
    props.setSelectedState([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        isClearable={true}
        name="state"
        onChange={(newValue: any) => {
          if (newValue) {
            props.setSelectedState([newValue.value]);
          } else {
            props.setSelectedState([]);
            state?.getSearchedData("");
            setSearch("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            state?.getSearchedData(newValue);
          }
        }}
        options={stateList}
        value={
          selectedData?.length > 0 &&
          props?.selectedState?.length > 0 &&
          stateList?.filter((option: any) =>
            props?.selectedState?.includes(option.value)
          )
        }
      />
      <Label>State</Label>
    </div>
  );
}

export function IndustryFilter(props: any) {
  const { selectedData, search, setSearch } = props;
  let industryData: any = null;

  if (selectedData?.length > 0) {
    industryData = IndustryAPI({
      ...props.filterBody,
      pagination: 0,
      // pagination: search?.length > 0 ? 0 : 1,
    });
  }
  const industryList = industryData?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.industryName,
    }));
  if (selectedData?.length === 0 && props?.selectedIndustry?.length > 0) {
    setSearch([]);
    props.setSelectedIndustry([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        name="industryId"
        onChange={(newValue: any) => {
          if (newValue) {
            props.setSelectedIndustry([newValue.value]);
          } else {
            props.setSelectedIndustry([]);
            industryData?.getSearchedData();
            setSearch("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            industryData?.getSearchedData(newValue);
          }
        }}
        isClearable={true}
        options={industryList}
        value={
          selectedData?.length > 0 &&
          props?.selectedIndustry?.length > 0 &&
          industryList?.filter((option: any) =>
            props?.selectedIndustry?.includes(option.value)
          )
        }
      />
      <Label>Industry</Label>
    </div>
  );
}
export const Jobfilter = (props: any) => {
  const {
    setSelectedJob,
    selectedJob,
    selectedData,
    filterBody,
    search,
    fieldName,
    setSearch,
  } = props;
  let job: any = [];
  if (selectedData?.length > 0) {
    job = JobAPI({ ...filterBody, pagination: search?.length > 0 ? 0 : 1 });
  }
  const jobList = job?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => {
      return {
        value: item.id,
        label: item.jobName,
      };
    });
  if (selectedData?.length === 0 && props?.selectedJob?.length > 0) {
    setSearch([]);
    props.setSelectedJob([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        name="Job"
        isClearable={true}
        onChange={(newValue: any) => {
          if (newValue) {
            setSelectedJob([newValue.value]);
          } else {
            setSelectedJob([]);
            setSearch("");
            job?.getSearchedData("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            job?.getSearchedData(newValue);
          }
          if (selectedData?.length === 0) {
            setSearch([]);
            setSelectedJob([]);
          }
        }}
        options={jobList}
        value={
          selectedData?.length > 0 &&
          selectedJob?.length > 0 &&
          jobList?.filter((option: any) => selectedJob?.includes(option.value))
        }
      />
      <Label>{fieldName ? fieldName : "Job"}</Label>
    </div>
  );
};
export const Skillfilter = (props: any) => {
  const {
    setSelectedSkill,
    selectedSkill,
    selectedData,
    filterBody,
    search,
    setSearch,
  } = props;
  let skill: any = [];
  if (selectedData?.length > 0) {
    skill = SkillAPI({ ...filterBody, pagination: search?.length > 0 ? 0 : 1 });
  }
  const skillList = skill?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.skillName,
    }));
  if (selectedData?.length === 0 && props?.selectedSkill?.length > 0) {
    setSearch([]);
    props.setSelectedSkill([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        name="skillId"
        isClearable={true}
        onChange={(newValue: any) => {
          if (newValue) {
            setSelectedSkill([newValue.value]);
          } else {
            setSelectedSkill([]);
            skill?.getSearchedData("");
            setSearch("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            skill?.getSearchedData(newValue);
          }
        }}
        options={skillList}
        value={
          selectedSkill?.length > 0 &&
          skillList?.filter((option: any) =>
            selectedSkill?.includes(option.value)
          )
        }
      />
      <Label>Skill</Label>
    </div>
  );
};

export const Professionfilter = (props: any) => {
  const {
    setSelectedProfession,
    selectedProfession,
    selectedData,
    filterBody,
    search,
    setSearch,
  } = props;
  let profession: any = [];
  if (selectedData?.length > 0) {
    profession = ProfessionAPI({
      ...filterBody,
      pagination: search?.length > 0 ? 0 : 1,
    });
  }
  const professionList = profession?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.professionName,
    }));
  if (selectedData?.length === 0 && props?.selectedProfession?.length > 0) {
    setSearch([]);
    props.setSelectedProfession([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        isClearable={true}
        name="professionId"
        onChange={(newValue: any) => {
          if (newValue) {
            setSelectedProfession([newValue.value]);
          } else {
            setSelectedProfession([]);
            profession?.getSearchedData("");
            setSearch("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            profession?.getSearchedData(newValue);
          }
        }}
        options={professionList}
        value={
          selectedProfession?.length > 0 &&
          professionList?.filter((option: any) =>
            selectedProfession?.includes(option.value)
          )
        }
      />
      <Label>Profession</Label>
    </div>
  );
};
export const Conceptfilter = (props: any) => {
  const {
    selectedKey,
    setSelectedKey,
    selectedData,
    search,
    setSearch,
    filterBody,
  } = props;
  let concept: any = [];
  if (selectedData?.length > 0) {
    concept = KeyAPI({
      ...filterBody,
      pagination: search?.length > 0 ? 0 : 1,
    });
  }
  const keyConcept = concept?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.conceptName,
    }));
  if (selectedData?.length === 0 && props?.selectedKey?.length > 0) {
    setSearch([]);
    props.setSelectedKey([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        isClearable={true}
        name="keyConcept"
        onChange={(newValue: any) => {
          if (newValue) {
            setSelectedKey([newValue.value]);
          } else {
            setSelectedKey([]);
            concept?.getSearchedData("");
            setSearch("");
          }
        }}
        options={keyConcept}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            concept?.getSearchedData(newValue);
          }
        }}
        value={
          selectedKey?.length > 0 &&
          keyConcept?.filter((option: any) =>
            selectedKey?.includes(option.value)
          )
        }
      />
      <Label>key Concept</Label>
    </div>
  );
};
export const Designationfilter = (props: any) => {
  const {
    setSelectedDesignation,
    selectedDesignation,
    selectedData,
    filterBody,
    search,
    setSearch,
  } = props;
  let Designation: any = [];
  if (selectedData?.length > 0) {
    Designation = DesignationAPI({
      ...filterBody,
      pagination: search?.length > 0 ? 0 : 1,
    });
  }
  const designationList = Designation?.data?.list
    ?.filter((item: any) => item.isActive === true)
    .map((item: any) => ({
      value: item.id,
      label: item.designationName,
    }));
  if (selectedData?.length === 0 && props?.selectedDesignation?.length > 0) {
    setSearch([]);
    props.setSelectedDesignation([]);
  }
  return (
    <div className="form-floating sticky_label mb-0">
      <ReactSelect
        name="Designation"
        isClearable={true}
        onChange={(newValue: any) => {
          if (newValue) {
            setSelectedDesignation([newValue.value]);
          } else {
            setSelectedDesignation([]);
            Designation?.getSearchedData();
            setSearch("");
          }
        }}
        onInputChange={(newValue: any) => {
          if (newValue?.length > 0) {
            setSearch(newValue);
            // newValue?.length > 1 &&
            Designation?.getSearchedData(newValue);
          }
        }}
        options={designationList}
        value={designationList?.filter((option: any) =>
          selectedDesignation?.includes(option.value)
        )}
      />
      <Label>Designation</Label>
    </div>
  );
};
