import React, { useState, useEffect } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  UncontrolledAccordion,
} from "reactstrap";
import { useFilterHelper } from "../../helper/filterHelper";
import { TopicAPI } from "../topic/list";
import { ExpirenceFilter } from "./filter";
import { QuestionAi } from "./list";
import { useNavigate } from "react-router-dom";
import { leftaerrowIcon } from "../../constant/icons";

export default function QuestionDetails() {
  const {
    selectedValue,
    selectedIndustry,
    selectedKey,
    selectedSkill,
    selectedJob,
    selectedDesignation,
    selectedProfession,
    topicIds,
    setTopicIds,
    questionType,
  } = useFilterHelper();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedKey?.length === 0) {
      navigate("/question");
    }
  }, [selectedKey]);
  const api = TopicAPI({
    countryId: selectedValue,
    industryId: selectedIndustry,
    professionId: selectedProfession,
    skillId: selectedSkill,
    keyConceptId: selectedKey,
    jobId: selectedJob,
    designationId: selectedDesignation,
    pagination: 0,
  });
  const topic = api.data?.list;
  const [experience, setExperience] = useState<string[]>([]);
  useEffect(() => {
    topicIds?.length === 0 && navigate("/question");
  }, [topicIds]);
  return (
    <>
      <div className="question_accordion">
        <div
          className={
            topicIds?.length > 0
              ? "rounded-0 border-0 p-0 question_details"
              : ""
          }
        >
          {topic?.length > 0 &&
            topic.map((data: any, id: string) => {
              const isTopicIdIncluded = topicIds.includes(data.id);
              if (data.id === topicIds?.[0]) {
                return (
                  <>
                    <div
                      className="question_head"
                      key={id}
                      // targetId={questionType + data.id}
                    >
                      <Button
                        onClick={() => {
                          setExperience([]);
                          setTopicIds([]);
                        }}
                      >
                        {leftaerrowIcon.icon}
                      </Button>
                      <span>{data.topicName}</span>
                    </div>

                    <div
                      className="question_body"
                      // accordionId={questionType + data.id}
                    >
                      {topicIds.length > 0 && isTopicIdIncluded && (
                        <ExpirenceFilter
                          api={api}
                          setExperience={setExperience}
                          experience={experience}
                        />
                      )}
                      {isTopicIdIncluded && experience.length > 0 ? (
                        <QuestionAi
                          topicIds={topicIds}
                          selectedIndustry={selectedIndustry}
                          selectedProfession={selectedProfession}
                          selectedSkill={selectedSkill}
                          selectedValue={selectedValue}
                          selectedKey={selectedKey}
                          experience={experience}
                          questionType={questionType}
                        />
                      ):<h3 className="year_not_selected">Please select year of experience</h3>}
                    </div>
                  </>
                );
              }
            })}
        </div>
      </div>
    </>
  );
}
