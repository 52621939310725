import { Badge, Button } from "reactstrap";
import {
  allquestionIcon,
  reviewIcon,
  rightaerrowIcon,
  viewIcon,
} from "../../constant/icons";
import Nodata_found from "../../assets/images/no_data_found.png";
import { TopicAPI } from "../topic/list";
import CommonPagination from "../../components/pagination/custopmPagination";
import { useNavigate } from "react-router-dom";
import { useFilterHelper } from "../../helper/filterHelper";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { useEffect } from "react";
import QuestionBox from "./questionBox";
const ViewModal = (props: any) => {
  const { column, rowData } = props;
  return (
    <button onClick={() => column.callBack(rowData)}>{viewIcon.icon}</button>
  );
};

const questionscore = (props: any) => {
  const {
    disabled,
    column,
    dispatch,
    isDetailsRowShown,
    rowData,
    rowKeyValue,
    value,
    message,
  } = props;
  return (
    <div
      className={
        rowData.id === rowKeyValue && isDetailsRowShown
          ? "key_concept view_full_concept "
          : "key_concept "
      }
      onClick={() => {
        dispatch(
          isDetailsRowShown
            ? hideDetailsRow(props.rowKeyValue)
            : showDetailsRow(props.rowKeyValue)
        );
      }}
    >
      <span className="question_texts">{props?.rowData?.question} &nbsp;</span>
      {props.rowData.score && <Badge> {props.rowData.score}</Badge>}
    </div>
  );
};

const AccodianView = (props: any) => {
  const navigate = useNavigate();
  const { questionType, setQuestionType } = useFilterHelper();
  const api = TopicAPI({ ...props.filterBody, pagination: 1 });
  const topic = api.data?.list;
  const { setTopicIds, topicIds } = props;
  useEffect(() => {
    if (props.selectedKey?.length > 0) {
      setQuestionType("All");
    } else {
      setQuestionType("key");
    }
  }, [props.selectedKey]);
  return (
    <div className="question_accordion">
      <>
        <Button
          className={questionType === "All" ? "active" : ""}
          onClick={() => {
            setQuestionType("All");

            // setExperience([]);
            setTopicIds([]);
          }}
        >
          <span>{allquestionIcon.icon}</span>
          All
        </Button>
        <Button
          className={questionType === "Review" ? "active ms-1" : "ms-1"}
          onClick={() => {
            // setExperience([]);
            setQuestionType("Review");
            setTopicIds([]);
          }}
        >
          <span>{reviewIcon.icon}</span>
          Question Bank
        </Button>
        {props.selectedKey?.length === 0 && (
          <Button
            className={questionType === "key" ? "active ms-1" : "ms-1"}
            onClick={() => {
              // setExperience([]);
              setQuestionType("key");
              setTopicIds([]);
            }}
          >
            <span>{reviewIcon.icon}</span>
            Run time Question
          </Button>
        )}
      </>

      <ul className={"question-row"}>
        {topic?.length > 0 &&
          topic.map((data: any, id: string) => {
            const isTopicIdIncluded = topicIds.includes(data.id);
            return (
              <li key={id}>
                <Button
                  targetId={questionType + data.id}
                  onClick={() => {
                    // setExperience([]);
                    navigate("/question/view");
                    setTopicIds([data.id]);
                  }}
                >
                  {data.topicName}
                  <span>{rightaerrowIcon.icon}</span>
                </Button>
              </li>
            );
          })}
        {!topic?.length && questionType !== "key" && (
          <div className="no_data_found_img">
            <img src={Nodata_found} alt="nodata_found" />
          </div>
        )}

        {topic?.length > 0 && (
          <CommonPagination
            data={api.data}
            updatePagination={api.updatePagination}
          />
        )}
        {props.selectedSkill && questionType === "key" && <QuestionBox />}
      </ul>
    </div>
  );
};

export { ViewModal, questionscore, AccodianView };
