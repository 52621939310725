import { Row, Col } from 'reactstrap'
import { AppMainDarkLogo } from '../../constant/logo'
import { useAuth } from '../../components/auth'

export const AuthLayout = (props: any) => {
    const auth: any = useAuth()
    return (
        <div className="login-page theme_01">
            <Row>
                <Col className="login-img">
                    <img alt="Sign in" src={props.Image} />
                </Col>

                <Col className="login-form">
                    <div className="login-main small-scroll">
                        <div className="loging-head">
                            <img alt="Logo" src={AppMainDarkLogo} />
                        </div>
                        <div className="loging-content">
                            <h2>{props.Title}</h2>
                            <p>{props.SubTitle}</p>
                            {props.children}
                        </div>
                        <div className="text-center copy-right w-100">
                            <p>Copyright © {new Date().getFullYear()} by JobIntel</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}





