import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useApi } from "../../helper/apiHook";
import { EmailPlaceHolders } from "./emailPlaceHolders";
import { EmailTemapleType } from "./emailTemapleType";
import { EmailDetails } from "./emailTemapleDetails";
import { initialTemplateState } from "./common";

const EmailTemplateList = () => {
  const [switchStatus, setSwitchStatus] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState<number>(1);
  const [roleId, setRoleId] = useState<number>(1);
  const [initialTemplateValues, setInitialTemplateValues] = useState(
    initialTemplateState()
  );
  const api = useApi(
    `emailTemplate?templateId=${templateId}&roleId=${roleId}`,
    false
  );
  useEffect(() => {
    api.mutate();
  }, [templateId, roleId]);

  //Getting Role,template,emailDetailsData
  const { data } = api.GetValue("/emailTemplateConfigration");
  const emailTemplateTypes = (data as any)?.emailTemplateType;
  const emailTemplateFields = (data as any)?.emailCustomFields;
  const templteDetailsData = api.data;
  const roleData = api.GetValue("role");

  useEffect(() => {
    if (templateId) {
      // const getData = templteDetailsData?.find(
      //   (item: any) => item?.id === templateId
      // );
      const getData = templteDetailsData ? templteDetailsData[0] : [];
      setSwitchStatus(getData?.isActive);
      setInitialTemplateValues({
        ...initialTemplateValues,
        id: getData?.id,
        subject: getData?.subject,
        emailBody: getData?.emailBody,
      });
    }
  }, [templteDetailsData]);

  const formSubmit = async (values: any) => {
    api.updateValue(values);
  };

  return (
    <PageLayout
      title="Email Template"
      View={
        <div className="container">
          <Row className="m-0 mt-4 template-box">
            <EmailTemapleType
              emailTemplateTypes={emailTemplateTypes}
              templateId={templateId}
              setTemplateId={setTemplateId}
            />
            <EmailDetails
              initialTemplateValues={initialTemplateValues}
              roleId={roleId}
              setRoleId={setRoleId}
              roleData={roleData?.data}
              updateStatus={api.status}
              switchStatus={switchStatus}
              formSubmit={formSubmit}
            />
            <EmailPlaceHolders EmailFields={emailTemplateFields} />
          </Row>
        </div>
      }
    />
  );
};

export { EmailTemplateList };
