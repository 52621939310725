import ReactSelect from "react-select";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { StateAPI } from "../state/list";
import { status } from "../../constant/commonConstants";
import { Filter_Icon, close } from "../../constant/icons";
import {
  CountryFilter,
  StateFilter,
  StatusFilter,
} from "../../components/Filter/common";
import { useState } from "react";

export default function CityFilter(props: any) {
  const [search, setSearch] = useState("");


  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} className="filter_head">
              <h6>{Filter_Icon.icon}Filter</h6>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  className="clear_btn btn-primary"
                  color="link"
                  onClick={() => {
                    props.setSelectedValue([]);
                    props.setSelectedState([]);
                    props.setStatus([]);
                  }}
                >
                  Clear all
                </Button>
                <Button
                  className="my-tooltip cancel_filter"
                  data-tooltip-content={"Close filter"}
                  color="link"
                  onClick={props.handleFilterToggle}
                >
                  {close.icon}
                </Button>
              </div>
            </Col>
            <Col lg={4}>
            <CountryFilter
                selectedValue={props.selectedValue}
                setSelectedValue={props.setSelectedValue}
              />
            </Col>
            <Col lg={4}>
              <StateFilter
                setSelectedState={props.setSelectedState}
                selectedState={props.selectedState}
                selectedData={props.selectedValue}
                search={search}
                setSearch={setSearch}
                filterBody={{
                  countryId: props.selectedValue,
                }}
              />
            </Col>
            <Col lg={4}>
              <StatusFilter setStatus={props.setStatus} status={props.status} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
