import { Button } from "reactstrap";
import page_404 from "../../src/assets/images/404-page_img.png";
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="no_data_found_img page_404">
        <img src={page_404} alt="dashboard" />
        <h2>Page Not Found</h2>
        <p>The Requested Page Does Not Exist</p>
        <Button color="primary" onClick={() => navigate("/dashboard")}>
          Back to home
        </Button>
      </div>
    </>
  );
};

export default Page404;
