
import { useLoaderHelper } from "../../helper/loaderHelper";

export default function Loader({ }) {
    const { dataIsLoading } = useLoaderHelper();
    return (
        <>
            {dataIsLoading && <div className="preloader-main">
                <div className="preloader-wrapper">
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                    <div className="loader">
                        <div className="dot"></div>
                    </div>
                </div>
            </div>}
        </>
    )
}