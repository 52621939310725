import "./scss/style.scss";
import { AuthProvider } from "./components/auth";
import { Route, Routes } from "react-router-dom";
import Login from "./modules/auth/login";
import { RequireAuth } from "./components/requireAuth";
import { AuthLayout } from "./modules/auth/auth";
import { ForgotPassword } from "./modules/auth/forget";
import { ResetPassword } from "./modules/auth/reset";
import {
  AppMainLogo,
  ForgotPasswordSubTitle,
  ForgotPasswordTitle,
  LogInSubTitle,
  LogInTitle,
  ResetPasswordImage,
  ResetPasswordSubTitle,
  ResetPasswordTitle,
} from "./modules/auth/constant";
import UserList from "./modules/user/list";
import { Layout } from "./modules/layout/layout";

import { EmailTemplateList } from "./modules/emailTemplate/list";
import Page404 from "./modules/page404";
import { AppProvider } from "./components/app";
import Dashboard from "./modules/dashboard";
import CountryList from "./modules/country/list";
import IndustryList from "./modules/industry/list";
import ProfessionList from "./modules/profession/list";
import JobList from "./modules/Job/list";
import SkillList from "./modules/skill/list";
import DesignationList from "./modules/designation/list";
import TopicList from "./modules/topic/list";
import ConceptList from "./modules/concept/list";
import { LoadingProvider } from "./helper/loaderHelper";
import StateList from "./modules/state/list";
import CityList from "./modules/city/list";
import TopicWiseQuestionList from "./modules/questionAi/topicwisequestion";
import QuestionDetails from "./modules/questionAi/questionDetails";


function App() {
  return (
    <>
      <LoadingProvider>
        <AuthProvider>
          <AppProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthLayout
                    Title={LogInTitle}
                    SubTitle={LogInSubTitle}
                    Image={AppMainLogo}
                  >
                    <Login />
                  </AuthLayout>
                }
              />
              <Route
                path="/ForgotPassword"
                element={
                  <AuthLayout
                    Title={ForgotPasswordTitle}
                    SubTitle={ForgotPasswordSubTitle}
                    Image={ResetPasswordImage}
                  >
                    <ForgotPassword />
                  </AuthLayout>
                }
              />
              <Route
                path="/reset/:token"
                element={
                  <AuthLayout
                    Title={ResetPasswordTitle}
                    SubTitle={ResetPasswordSubTitle}
                    Image={ResetPasswordImage}
                  >
                    <ResetPassword />
                  </AuthLayout>
                }
              />
              <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/user" element={<UserList />} />
                  <Route path="/city" element={<CityList />} />
                  <Route path="/state" element={<StateList />} />
                  <Route path="/country" element={<CountryList />} />
                  <Route path="/industry" element={<IndustryList />} />
                  <Route path="/profession" element={<ProfessionList />} />
                  <Route path="/job" element={<JobList />} />
                  <Route path="/skill" element={<SkillList />} />
                  <Route path="/designation" element={<DesignationList />} />
                  <Route path="/question/view" element={<QuestionDetails />} />
                  <Route path="/topic" element={<TopicList />} />
                  <Route path="/key-concept" element={<ConceptList />} />
                  <Route
                    path="/emailTemplate"
                    element={<EmailTemplateList />}
                  />
                  <Route path="/question" element={<TopicWiseQuestionList />} />
                </Route>
              </Route>
              <Route path="*" element={<Page404 />} />
            </Routes>
          </AppProvider>
        </AuthProvider>
      </LoadingProvider>
    </>
  );
}

export default App;
