import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Pagination from "react-bootstrap/Pagination";
export default function CommonPagination({
    direction,
    setPaginationData,
    data,updatePagination,
    ...args
}:any) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const toggle = () => setDropdownOpen((prevState:any) => !prevState);
    const totalData = data?.totalRecords ? data.totalRecords : 0
    const pageNumbers:any = [];
    for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
        pageNumbers.push(i);
    }

    let start = 1,
        end = pageNumbers?.length;
    if (currentPage - 2 > 1) {
        start = currentPage - 2;
    }
    if (currentPage + 2 < pageNumbers?.length) {
        end = currentPage + 2;
    }
    const paginate = (pageNumber:number) => {
        setCurrentPage(pageNumber);
        updatePagination()?.updateCurrentPageNo(pageNumber-1);
    };
    const handleDataPerPageChange = (event:any) => {
        setDataPerPage(Number(event));
        updatePagination()?.updatePageLimit(Number(event));
    };
    const prevDataPerPage = useRef(dataPerPage);

    // Update the ref when dataPerPage changes
    // useEffect(() => {
    //     prevDataPerPage.current = dataPerPage;
    // }, [dataPerPage]);

    useEffect(() => {
        // Compare the current dataPerPage with the previous value
        if (dataPerPage !== prevDataPerPage.current) {
            setCurrentPage(1);
        }
    }, [dataPerPage]);
    return (
        <div className={`table-pagination mt-3`}>
            <div className="record-perpage">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                    <DropdownToggle caret>{dataPerPage}</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => handleDataPerPageChange("10")}>
                            10
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDataPerPageChange("25")}>
                            25
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDataPerPageChange("50")}>
                            50
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDataPerPageChange("100")}>
                            100
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <span className="totle_records">{totalData} Records</span>
            </div>
            <Pagination>
                <Pagination.First
                    onClick={() => paginate(1)}
                    disabled={currentPage === 1}
                />
                <Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {start !== 1 && <Pagination.Ellipsis />}
                {pageNumbers.slice(start - 1, end).map((number:any) => (
                    <Pagination.Item
                        key={number}
                        onClick={() => paginate(number)}
                        active={currentPage === number}
                    >
                        {number}
                    </Pagination.Item>
                ))}
                {end !== pageNumbers?.length && <Pagination.Ellipsis />}
                <Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === pageNumbers?.length}
                />
                <Pagination.Last
                    onClick={() => paginate(pageNumbers?.length)}
                    disabled={currentPage === pageNumbers?.length}
                />
            </Pagination>
        </div>
    );
}
