import React, { useEffect, useState } from "react";

import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { IndustryAPI } from "./list";

export default function IndustryView(props: any) {
  const { selectedValue, payload, search } = props;
  let api: any = [];
  if (selectedValue?.length > 0 || search) {
    api = IndustryAPI({ ...payload, searchText: search });
  }
  // useEffect(() => {
  //   api.updatePagination?.resetPagination();
  // }, [...payload]);
  
  return (
    <>
      <DataView
        columns={columns({ api })}
        rows={api?.data}
        updatePagination={api.updatePagination}
      />
    </>
  );
}
