import { Field, Form, Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { post } from "../../helper/api";
import { useLoaderHelper } from "../../helper/loaderHelper";
import React, { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../i18n/translator";

export const ResetPassword = (props: any) => {
  const { token } = useParams();
  const loader = useLoaderHelper();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  const validator = useRef(new SimpleReactValidator());
  const onSubmitForm = async (values: any, actions: any) => {
    const result = await post(
      "/admin/reset-password",
      {
        token: token,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      },
      true,
      loader
    );
    if (result?.status) {
      navigate("/");
    }
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitForm}>
      {(values: any) => (
        <Form className="form-horizontal form-login row">
          <FormGroup>
            <Label className="form-label" htmlFor="password"></Label>
            <Field
              className="form-control"
              id="password"
              name="password"
              placeholder="New password"
              type="password"
            />
            {validator.current.message(
              __t("validations.auth.password_required"),
              values.password,
              "required",
              {
                messages: {
                  required: __t("validations.auth.password_required"),
                },
              }
            )}
          </FormGroup>
          <FormGroup>
            <Label className="form-label" htmlFor="password"></Label>
            <Field
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
            />
            {validator.current.message(
              __t("validations.auth.confirm_password"),
              values.confirmPassword,
              "required",
              {
                messages: {
                  required: __t("validations.auth.confirm_password"),
                },
              }
            )}
          </FormGroup>
          <Col lg="12">
            <Button color="primary" block className="w-100" type="submit">
              Submit
            </Button>
          </Col>
          <Col lg="12" className="text-center">
            <Link to="/" className="pt-3 d-inline-block mx-auto">
              Sign in
            </Link>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
