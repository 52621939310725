import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { Button } from "reactstrap";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { filterIcon, plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import ProfessionFilter from "./filter";
import ProfessionView from "./table";
export const ProfessionAPI = (data: any) => {
  return { ...useApi("admin/profession", true, data, {}, false, true) };
};

function ProfessionList(props: any) {
  const app = useApp();
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");

  const payload = {
    pagination: 1,
    countryId: selectedValue,
    status:status,
    isActive: status,
    industryId: selectedIndustry,
  }
  useEffect(() => {
    app.updateConversation("Profession");
  }, []);
  const [filter, setFilter] = useState(true);
  const handleFilterToggle = () => {
    setFilter(!filter);
  };
  return (
    <PageLayout
      title="Profession List"
      item={
        <>
          <SearchData getSearchedData={setSearch} />
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <ProfessionFilter
            setSelectedValue={setSelectedValue}
            selectedIndustry={selectedIndustry}
            selectedValue={selectedValue}
            setSelectedIndustry={setSelectedIndustry}
            setStatus={setStatus}
            status={status}
            handleFilterToggle={handleFilterToggle}
          />
        )
      }
      View={
        <ProfessionView
        selectedValue={selectedIndustry}
        payload={payload}
        search={search}
        />
      }
    />
  );
}

export default ProfessionList;
