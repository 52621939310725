import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { Button } from "reactstrap";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { filterIcon, plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import CityFilter from "./filter";
import CityView from "./table";
// import FilterData from "./filter";
export const CityAPI = (data: any) => {
  return { ...useApi("admin/city", true, data, {}, false, true) };
};

function CityList(props: any) {
  const [selectedState, setSelectedState] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const payload = {
    countryId: selectedValue,
    stateId: selectedState,
    pagination: 1,
    isActive: status,
    status:status
  };

  const app = useApp();
  const [filter, setFilter] = useState(true);
  const handleFilterToggle = () => {
    setFilter(!filter);
  };
  useEffect(() => {
    app.updateConversation("City");
  }, []);

  return (
    <PageLayout
      title="City List"
      item={
        <>
          <SearchData getSearchedData={setSearch} />
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <>
            <CityFilter
              setSelectedValue={setSelectedValue}
              setSelectedState={setSelectedState}
              setStatus={setStatus}
              handleFilterToggle={handleFilterToggle}
              selectedValue={selectedValue}
              selectedState={selectedState}
              status={status}
            />
          </>
        )
      }
      View={
        <CityView
          selectedValue={selectedState}
          payload={payload}
          search={search}
          status={status}
        />
      }
    />
  );
}

export default CityList;
