import { ErrorMessage, Field, Form, Formik } from "formik";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Nav,
  NavItem,
} from "reactstrap";
import { confirmBox } from "../../components/toast";
import { config } from "./common";

export const EmailDetails = (props: any) => {
  const editor = useRef(null);
  const {
    initialTemplateValues,
    roleData,
    roleId,
    switchStatus,
    setRoleId,
    formSubmit,
    updateStatus,
  } = props;
  return (
    <Formik
      initialValues={initialTemplateValues}
      // validationSchema={emailValidationSchema}
      enableReinitialize={true}
      onSubmit={formSubmit}
    >
      {({ values, setFieldValue }) => (
        <>
          <Col md="6" className="edit_template">
            <Form>
              <Nav tabs>
                {roleData?.map((item: any, index: any) => (
                  <NavItem key={index}>
                    <Button
                      className={roleId == item?.id ? "show" : ""}
                      color="link"
                      onClick={() => {
                        setRoleId(item?.id);
                      }}
                    >
                      {`To ${item?.name}`}
                    </Button>
                  </NavItem>
                ))}
              </Nav>
              <Card>
                <CardBody className="pt-0">
                  <FormGroup className="col-12 mt-3 mb-3">
                    <div className="form-check form-switch ms-1">
                      <input
                        className="form-check-input ms-1 me-2"
                        type="checkbox"
                        role="switch"
                        checked={switchStatus}
                        onChange={async () => {
                          const confirmBoxResponse = await confirmBox(
                            !switchStatus
                              ? "Are you sure you want to activate this template?"
                              : "Are you sure you want to deactivate this template?"
                          );

                          if (confirmBoxResponse) {
                            updateStatus({
                              id: initialTemplateValues?.id,
                              isActive: !switchStatus,
                            });
                          }
                        }}
                      />
                      <label className="form-check-label">
                        Send Notification
                      </label>
                    </div>
                  </FormGroup>
                  <div className="no-floating-label">
                    <FormGroup className="col-12 mt-2">
                      <Label className="form-label" for="exampleEmailSubject">
                        Email Subject
                        <span className="text-danger required">*</span>
                      </Label>
                      <Field
                        className="form-control"
                        type="text"
                        name="subject"
                        id="exampleEmailSubject"
                        placeholder="Email Subject"
                      />
                      <ErrorMessage
                        name="subject"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </div>

                  <FormGroup className="mb-1">
                    <FormGroup className="mb-1">
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={values.emailBody}
                        onChange={(newContent) => {
                          setFieldValue("emailBody", newContent);
                        }}
                      />
                      <ErrorMessage
                        name="emailBody"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </FormGroup>
                  <span className="d-block">
                    Allowed HTML tags &lt;div&gt;, &lt;label&gt;,
                    &lt;button&gt;, &lt;span&gt;, &lt;p&gt;, &lt;ul&gt;,
                    &lt;li&gt;, &lt;tr&gt;, &lt;td&gt;, &lt;a&gt;, &lt;br&gt;,
                    &lt;b&gt;, &lt;h1&gt;, &lt;h2&gt;, &lt;hr&gt;
                  </span>
                  <Button
                    type="submit"
                    color="primary"
                    block
                    className="w-auto waves-effect waves-light float-end"
                  >
                    Submit
                  </Button>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </>
      )}
    </Formik>
  );
};
