import useSWR from "swr";
import {
  del,
  fetcher,
  fetcherPost,
  get,
  post,
  statusUpdate,
  update,
} from "./api";
import { useState, useEffect, useRef } from "react";
import { serverRequest } from "./types";
import { useAuth } from "../components/auth";
import { useLoaderHelper } from "./loaderHelper";

// Define the structure of the API response
export interface useApiType {
  data: any;
  isLoading: boolean;
  error: any;
  create: (value: any) => Promise<any>;
  updateValue: (value: any, id: String | number) => void;
  removeValue: (id: String | number) => void;
  status: (value: any) => void;
  GetValue: (url: string, body?: object) => any;
  serverRequest: (props: serverRequest) => any;
}

// Define the structure of the pagination parameters
interface paginationType {
  limit?: number;
  page?: number;
}
interface searchType {
  searchText: string;
}
export const useApi = (
  route: string,
  IsPagination: boolean = false,
  body: any = {},
  dataLoad: any = {},
  isDataLoading: boolean = false,
  isPost: boolean = false
) => {
  const auth = useAuth();
  const routeLink = `/${route}`;
  const [pagination, setPagination] = useState<paginationType>({
    page: 0,
    limit: 10,
  });
  const [searchData, setSearchData] = useState<searchType>({
    searchText: "",
  });
  const bodyRef = useRef(body);
  useEffect(() => {
    if (JSON.stringify(bodyRef.current) === JSON.stringify(body)) {
    } else {
      setPagination({
        page: 0,
        limit: 10,
      });
    }
    bodyRef.current = body;
  }, [JSON.stringify(bodyRef.current) !== JSON.stringify(body)]);
  let bodyOfRequest = { ...body };
  if (IsPagination) {
    bodyOfRequest = {
      ...pagination,
      ...bodyOfRequest,
      searchText: bodyOfRequest.searchText
        ? bodyOfRequest.searchText
        : searchData.searchText,
    };
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [pagination.limit]);
  const loader = useLoaderHelper();

  // Use SWR to fetch data
  const { data, error, isLoading, isValidating, mutate } = useSWR<any[], Error>(
    auth.user && !isDataLoading ? [routeLink, bodyOfRequest] : null,
    isPost
      ? () => fetcherPost(routeLink + "-list", bodyOfRequest, loader)
      : () => fetcher(routeLink, bodyOfRequest, loader),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      ...dataLoad,
    }
  );

  let dataList: any = [];
  if (IsPagination && data) {
    dataList = data;
  } else {
    dataList = data;
  }
  const dataRefresh = (result: any) => {
    if (result?.status) {
      mutate();
    }
    return result;
  };
  useEffect(() => {
    bodyOfRequest.status !== null &&
      bodyOfRequest.status !== undefined &&
      mutate();
  }, [bodyOfRequest.status]);
  const updatePagination = () => {
    const resetPagination = () => {
      setPagination({
        page: 0,
        limit: 10,
      });
    };

    function updateCurrentPageNo(currentPageNo: number = 0) {
      return setPagination({
        ...pagination,
        page: currentPageNo,
      });
    }

    function updatePageLimit(pageLimit: number = 10) {
      setPagination({
        ...pagination,
        limit: pageLimit,
      });
    }
    function getCurrentPageNo(): number {
      return pagination.page === undefined ? 0 : (pagination.page as number);
    }

    function getTotalPage() {
      if (dataList?.totalPages > 1) {
        return dataList?.totalPages;
      } else {
        return 1;
      }
    }
    function getTotalRecords() {
      if (dataList?.totalRecords) {
        return dataList.totalRecords;
      }
    }
    return {
      updatePageLimit,
      updateCurrentPageNo,
      getCurrentPageNo,
      getTotalPage,
      getTotalRecords,
      resetPagination,
    };
  };

  function getSearchedData(data: string) {
    setSearchData({
      searchText: data,
    });
  }
  // Functions for API operations
  const status = async (values: any) => {
    return await statusUpdate(routeLink, values, loader).then(dataRefresh);
  };
  const create = async (
    values: any,
    response: Boolean,
    rote: any
  ): Promise<any> => {
    return await post(rote ? rote : routeLink, values, response, loader).then(
      dataRefresh
    );
  };
  const updateValue = async (value: any) => {
    return await update(routeLink, value, loader).then(dataRefresh);
  };
  const removeValue = async (id: String | number) => {
    return await del(`${routeLink}${id}`, loader).then(dataRefresh);
  };

  // Function to fetch data for a specific URL
  const GetValue = (url: string, body: object = {}) => {
    const { data, error, isLoading } = useSWR<any[], Error>(
      [url, body],
      () => fetcher(url, body, loader),
      {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
      }
    );
    useEffect(() => {
      loader.setDataIsLoading(isLoading);
    }, [isLoading]);
    return { data, error, isLoading };
  };

  // Return the functions and data as an object
  return {
    data: dataList,
    isLoading,
    error,
    create,
    updateValue,
    removeValue,
    status,
    GetValue,
    mutate,
    updatePagination,
    getSearchedData,
  };
};
