import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { StatusFilter } from "../../components/Filter/common";
import { Filter_Icon, close } from "../../constant/icons";

const CountryFilter = (props: any) => {
  const { setStatus, handleFilterToggle } = props;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg={12} className="filter_head">
            <h6>{Filter_Icon.icon}Filter</h6>
            <div className="d-flex justify-content-end gap-3">
              <Button
                className="clear_btn btn-primary"
                color="link"
                onClick={() => {
                  setStatus([]);
                }}
              >
                Clear all
              </Button>
              <Button
                className="my-tooltip cancel_filter"
                data-tooltip-content={"Close filter"}
                onClick={handleFilterToggle}
                color="link"
              >
                {close.icon}
              </Button>
            </div>
          </Col>
          <Col lg={4}>
            <StatusFilter setStatus={props.setStatus} status={props.status} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CountryFilter;
