export const getInitialsFirstCharechter = (
  firstName: any,
  lastName: any
) => {
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
};
export const getValueBykey = (key: string) => {
  return localStorage.getItem(key);
};