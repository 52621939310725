import React, { useEffect } from "react";
import { StateAPI } from "./list";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";

export default function StateView(props: any) {
  const { selectedValue, status, payload, search } = props;
  const api: any =
    selectedValue?.length > 0 || search
      ? StateAPI({ ...payload, searchText: search })
      : [];
 
  return (
    <>
      <DataView
        columns={columns({ api })}
        rows={api?.data}
        updatePagination={api.updatePagination}
      />
    </>
  );
}
