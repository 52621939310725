import { useFormik } from "formik";
import { useAuth } from "../../components/auth";
import { LoginFrom } from "./type";
import { Col, Label, Button, Spinner } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { __t } from "../../i18n/translator";

const initialValues: LoginFrom = {
  email: "",
  password: "",
};

const Login = () => {
  const auth = useAuth();
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);
  const loginFrom = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (validator.current.allValid()) {
        auth.login(values);
      } else {
        validator.current.showMessages();
      }
    },
  });
  return (
    <form
      className="form-horizontal form-login row"
      onSubmit={loginFrom.handleSubmit}
    >
      <div className="form-group">
        <Label className="form-label" htmlFor="email">
          {" "}
        </Label>
        <input
          className="form-control"
          id="email"
          name="email"
          placeholder="Email Address"
          type="email"
          onChange={loginFrom.handleChange}
          value={loginFrom.values.email}
        />
        {validator.current.message(
          __t("validations.auth.email"),
          loginFrom.values.email,
          "required",
          {
            messages: {
              required: __t("validations.auth.email"),
            },
          }
        )}
      </div>
      <div className="form-group">
        <Label className="form-label" htmlFor="password"></Label>
        <input
          className="form-control"
          id="password"
          name="password"
          placeholder="Password"
          type="password"
          onChange={loginFrom.handleChange}
          value={loginFrom.values.password}
        />
        {validator.current.message(
          __t("validations.auth.password"),
          loginFrom.values.password,
          "required",
          {
            messages: {
              required: __t("validations.auth.password"),
            },
          }
        )}
      </div>
      <Col lg="12">
        <Button color="primary" block className="w-100" type="submit">
          {auth.loader.dataIsLoading ? <Spinner size={"sm"}>Loading...</Spinner> : "Submit"}
        </Button>
      </Col>
      <Col lg="12" className="text-center">
        <Link to="/ForgotPassword" className="pt-3 d-inline-block mx-auto">
          Forgot Password
        </Link>
      </Col>
    </form>
  );
};

export default Login;
