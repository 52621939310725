import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import ConceptFilter from "./filter";
import { filterIcon } from "../../constant/icons";
import { Button } from "reactstrap";
import Concept from "./table";
export const KeyAPI = (data: any) => {
  return { ...useApi("admin/key-concept", true, data, {}, false, true) };
};

function ConceptList(props: any) {
  const app = useApp();
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState(true);
  const [search, setSearch] = useState("");
  const payload = {
    pagination: 1,
    countryId: selectedValue,
    status:status,
    isActive: status,
    industryId: selectedIndustry,
    professionId: selectedProfession,
    skillId: selectedSkill,
  } 
  const handleFilterToggle = () => {
    setFilter(!filter);
  };
  useEffect(() => {
    app.updateConversation("Key Concept");
  }, []);

  return (
    <PageLayout
      title="Key-concept List"
      item={
        <>
          <SearchData getSearchedData={setSearch} />
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <ConceptFilter
            setSelectedValue={setSelectedValue}
            setSelectedProfession={setSelectedProfession}
            setSelectedIndustry={setSelectedIndustry}
            setSelectedSkill={setSelectedSkill}
            selectedValue={selectedValue}
            handleFilterToggle={handleFilterToggle}
            status={status}
            selectedIndustry={selectedIndustry}
            selectedProfession={selectedProfession}
            selectedSkill={selectedSkill}
            setStatus={setStatus}
          />
        )
      }
      View={
        <Concept
        selectedValue={selectedSkill}
        payload={payload}
        search={search}
        />
      }
    />
  );
}

export default ConceptList;
