import Swal, { SweetAlertIcon } from "sweetalert2"
import 'animate.css'
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  }) 

const ToastConfirmBox=Swal.mixin({
  showDenyButton: true,
  confirmButtonText: 'Yes',
  denyButtonText: `No`,
  backdrop:true,
  allowOutsideClick: false,
  allowEscapeKey: false
})

const confirmBox=async(message:string,title:string="Are you sure?")=>{
  return await ToastConfirmBox.fire({
    title:title,
    text:message
  }).then((result)=>{
    return result.isConfirmed
  })
}
const message=(type:SweetAlertIcon,message:string,isHtml:boolean=false)=>{
    Toast.fire({
        icon: type,
        title:isHtml?undefined:message,
        html:isHtml?message:undefined
      })
} 
const comingSoon=()=>{
  Swal.fire({
		title: 'This feature is coming soon',
		showClass: {
	    	popup: 'animate__animated animate__tada'
	  	},
	  	hideClass: {
	    	popup: 'animate__animated animate__fadeOutUp'
	  	}
  	});
}
export {message,confirmBox,comingSoon}