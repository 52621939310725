import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { post } from "../../helper/api";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useLoaderHelper } from "../../helper/loaderHelper";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../i18n/translator";

interface ForgotPasswordFormValues {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const loader = useLoaderHelper();
  const validator = useRef(new SimpleReactValidator());
  const [isForget, setIsForgot] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isForget) {
      navigate("/");
    }
  }, [isForget, navigate]);

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    if (validator.current.allValid()) {
      const result = await post("/admin/forgot-password",{email: values.email},true, loader);
      setIsForgot(result.status);
    } else {
      validator.current.showMessages();
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      // validationSchema={forgotPasswordValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form className="form-horizontal form-login row">
          <div className="form-group">
            <Label className="form-label" htmlFor="email">
              {" "}
            </Label>
            <Field
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              type="email"
            />
            {validator.current.message(
              __t("validations.auth.email"),
              formProps.values.email,
              "required",
              {
                messages: {
                  required: __t("validations.auth.email"),
                },
              }
            )}
          </div>

          <Col lg="12">
            <Button color="primary" block className="w-100" type="submit">
              Forgot password
            </Button>
          </Col>
          <Col lg="12" className="text-center">
            <Link to="/" className="pt-3 d-inline-block mx-auto">
              Back to login
            </Link>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
