import { DataType } from "ka-table/enums";
import { TableColumn } from "../../components/table/table";
import { StatusControl } from "../../components/buttons/status";

export const columns = (actions: any): TableColumn[] => [
  {
    key: "id",
    title: "id",
    dataType: DataType.String,
    visible: false,
  },
  {
    key: "skillName",
    title: "Skill",
    dataType: DataType.String,
  },
  {
    key: "professionName",
    title: "Profession",
    dataType: DataType.String,
  },
  {
    key: "industryName",
    title: "Industry",
    dataType: DataType.String,
  },
  {
    key: "countryName",
    title: "Country",
    dataType: DataType.String,
  },

  {
    key: "isActive",
    title: "Status",
    dataType: DataType.Boolean,
    component: StatusControl,
    callBack: (row) => {
      actions.api.status({
        id: row.id,
        isActive: !row.isActive,
      });
    },
    style: {
      textAlign: "center",
    },
  }
];
