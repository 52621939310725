import React, { useEffect, useState } from 'react'
import { useFilterHelper } from '../../helper/filterHelper';
import { formatDateToYYYYMMDD } from '../../helper/utility';
import { QuestionAPI } from './list';
import { AccordionTable } from '../../components/table/accordionTable';
import { columns } from './columns';
interface paginationType {
    limit?: number;
    page?: number;
}
interface searchType {
    searchText: string;
}
export default function QuestionBox() {
    const { selectedDates, selectedValue, selectedIndustry, selectedProfession, selectedSkill, questionType } = useFilterHelper();
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState<paginationType>({
        page: 0,
        limit: 10,
    });
    const [searchData, setSearchData] = useState<searchType>({
        searchText: "",
    });

    const bodyResponse = {
        countryId: selectedValue,
        industryId: selectedIndustry,
        professionId: selectedProfession,
        skillId: selectedSkill,
        type: questionType,
        fromDate: selectedDates[0] ? formatDateToYYYYMMDD(selectedDates[0]) : "",
        toDate: selectedDates[1] ? formatDateToYYYYMMDD(selectedDates[1]) : "",
        ...pagination,
        ...searchData,
    };
    useEffect(() => {

        setPagination({
            page: 0,
            limit: 10,
        });
    }, []);
    useEffect(() => {
        setPagination({
            ...pagination,
            page: 0,
        });
    }, [pagination.limit])
    let api: any = [];
    if (questionType === "All") {
        api = QuestionAPI("questionAi/question", bodyResponse);
    } else {
        api = QuestionAPI("admin/question", bodyResponse);
    }

    useEffect(() => {
        api.mutate();
    }, []);

    useEffect(() => {
        if (api?.data?.totalRecords) {
            setTotal(api.data.totalRecords);
        } else {
            setTotal(0);
        }
    }, [api?.data]);
    return (
        <div>
            <AccordionTable
                columns={columns({
                    api,
                    questionType,
                })}
                rows={api.data}
                updatePagination={{
                    searchData,
                    setSearchData,
                    pagination,
                    setPagination,
                }}
            />
        </div>
    )
}
