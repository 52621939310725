import { DataType } from "ka-table/enums";
import { TableColumn } from "../../components/table/table";
import { StatusControl } from "../../components/buttons/status";
import { EditControl } from "../../components/buttons/edit";

export const columns = (actions: any): TableColumn[] => [
  {
    key: "id",
    title: "id",
    dataType: DataType.String,
    visible: false,
  },
  {
    key: "stateName",
    title: "State",
    dataType: DataType.String,
  },
  {
    key: "countryName",
    title: "Country",
    dataType: DataType.String,
  },
  {
    key: "isActive",
    title: "Status",
    dataType: DataType.Boolean,
    component: StatusControl,
    callBack: (row) => {
      actions.api.status({
        id: row.id,
        isActive: !row.isActive,
      });
    },
    style: {
      textAlign: "center",
    },
  },
];
