import { Button } from "reactstrap";
import {
  Move_Icon,
  deleteIcon,
  eyefillIcon,
  viewIcon,
} from "../../constant/icons";
import { useEffect, useState } from "react";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { confirmBox } from "../toast";
import { Tooltip } from "react-tooltip";
import { useFilterHelper } from "../../helper/filterHelper";
const InfoControl = (props: any) => {
  const { disabled, column, rowData, value, message } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <Button
        color="link"
        className="text-primary"
        onClick={() => column.callBack({ row: rowData, info: true })}
        id="Viewtooltip"
      >
        {viewIcon.icon}
      </Button>
    </>
  );
};

const InfoAcco = (props: any) => {
  const {
    disabled,
    column,
    dispatch,
    isDetailsRowShown,
    rowData,
    value,
    rowKeyValue,
    message,
    openDetailsRow,
    setOpenDetailsRow,
  } = props;
  const showHandle = () => {
    // dispatch(
    //   isDetailsRowShown
    //     ? hideDetailsRow(props.rowKeyValue)
    //     : showDetailsRow(props.rowKeyValue)
    // );
    if (openDetailsRow === rowKeyValue) {
      dispatch(hideDetailsRow(rowKeyValue));
      setOpenDetailsRow(-1);
    } else {
      dispatch(hideDetailsRow(openDetailsRow));
      dispatch(showDetailsRow(rowKeyValue));
      setOpenDetailsRow(rowKeyValue);
    }
  };

  return (
    <div className="table_action">
      <Button
        color="link"
        className="text-primary"
        id="detailstooltip"
        onClick={showHandle}
      >
        {rowData.id === props.rowKeyValue && isDetailsRowShown
          ? eyefillIcon.icon
          : viewIcon.icon}
      </Button>
      <Tooltip anchorSelect="#detailstooltip" place="bottom">
        View Details
      </Tooltip>
      <Button
        color="link"
        className="text-success"
        id="movequestiontooltip"
        onClick={async () => {
          const response = await confirmBox(message);
          if (response && column.callBack) {
            column.callBack({ ...rowData, status: true });
          }
        }}
      >
        {Move_Icon.icon}
      </Button>
      <Tooltip anchorSelect="#movequestiontooltip" place="bottom">
        Move to Question bank
      </Tooltip>
      <Button
        color="link"
        className="text-danger"
        id="Viewtooltip"
        onClick={async () => {
          const response = await confirmBox(message);
          if (response && column.callBack) {
            column.callBack(rowData);
          }
        }}
      >
        {deleteIcon.icon}
      </Button>
      <Tooltip anchorSelect="#Viewtooltip" place="bottom">
        Delete
      </Tooltip>
    </div>
  );
};
const ViewAccordion = (props: any) => {
  const {
    disabled,
    column,
    dispatch,
    isDetailsRowShown,
    rowData,
    value,
    rowKeyValue,
    message,
    openDetailsRow,
    setOpenDetailsRow,
  } = props;
  const showHandle = () => {
    // dispatch(
    //   isDetailsRowShown
    //     ? hideDetailsRow(props.rowKeyValue)
    //     : showDetailsRow(props.rowKeyValue)
    // );
    if (openDetailsRow === rowKeyValue) {
      dispatch(hideDetailsRow(rowKeyValue));
      setOpenDetailsRow(-1);
    } else {
      dispatch(hideDetailsRow(openDetailsRow));
      dispatch(showDetailsRow(rowKeyValue));
      setOpenDetailsRow(rowKeyValue);
    }
  };

  return (
    <div className="table_action">
      <Button
        color="link"
        className="text-primary"
        id="detailstooltip"
        onClick={showHandle}
      >
        {rowData.id === props.rowKeyValue && isDetailsRowShown
          ? eyefillIcon.icon
          : viewIcon.icon}
      </Button>
      <Tooltip anchorSelect="#detailstooltip" place="bottom">
        View Details
      </Tooltip>
    </div>
  );
};
export { InfoAcco, InfoControl, ViewAccordion };
