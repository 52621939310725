import { useState } from "react";

export const useAddUpdate = () => {
  const [viewAddUpdate, setViewAddUpdate] = useState<Boolean>(false);
  const [currentValue, setCurrentValue] = useState(null);
  const openAddEditFrom = (value: any) => {
    setCurrentValue(value);
    setViewAddUpdate(true);
  };
  const closeAddEditFrom = () => {
    setCurrentValue(null);
    setViewAddUpdate(false);
  };

  return { viewAddUpdate, currentValue, openAddEditFrom, closeAddEditFrom };
};
