import axios from "axios";
import { getToken } from "../components/auth";
import { message } from "../components/toast";
const API_URL = window.location.hostname === "localhost"
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_API;

const axiosInstance = axios.create({
  baseURL: API_URL,
});
axiosInstance.interceptors.request.use((config: any) => {
  const bearerToken = getToken();
  config.headers.Authorization = `Bearer ${bearerToken}`;
  return config;
});
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (window.localStorage.getItem("token")) {
        window.localStorage.clear();
        setTimeout(() => window.location.reload(), 1000);
      }
    }
    return Promise.reject(error);
  }
);

export const fetcher = (url: string, body: object = {}, loader: any) =>
  get(url, body, loader).then((res) => res.data);
  
export const fetcherPost = (url: string, body: object = {}, loader: any) =>
  post(url, body, false, loader).then((res) => res.data);

export const get = async <T>(url: string, data: any = {}, loader: any) => {
  let response: any = {};
  loader.setDataIsLoading(true);

  try {
    response = await axiosInstance.get(url, {
      params: data,
    });
  } catch (error: any) {
    response = error.response;
  } finally {
    loader.setDataIsLoading(false);
    if (response && response.data.message && response.data.message.length > 0) {
      if (response.data.status === false) {
        message("error", response.data.message);
      }
    }
    return response?.data;
  }
};

export const post = async <T>(
  url: string,
  data: T,
  showMessage: Boolean,
  loader: any
) => {
  let response: any = {};
  loader.setDataIsLoading(true);

  try {
    response = await axiosInstance.post(url, data);
  } catch (error: any) {
    response = error.response;
  } finally {
    loader.setDataIsLoading(false);
    if (response?.data?.message && response.data.message.length > 0) {
      if (response.data.status === false) {
        message("error", response.data.message);
      } else if (showMessage) {
        message("success", response.data.message);
      }

      return response.data;
    }
    return response;
  }
};
export const update = async <T>(url: string, data: T, loader: any) => {
  let response: any = {};
  loader.setDataIsLoading(true);

  try {
    response = await axiosInstance.put(url, data);
  } catch (error: any) {
    response = error.response;
  } finally {
    loader.setDataIsLoading(false);
    if (response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    return response.data;
  }
};

export const statusUpdate = async <T>(url: string, data: T, loader: any) => {
  let response: any = {};
  loader.setDataIsLoading(true);

  try {
    response = await axiosInstance.patch(url, data);
  } catch (error: any) {
    response = error.response;
  } finally {
    loader.setDataIsLoading(false);
    if (response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    return response.data;
  }
};

export const del = async <T>(url: string, loader: any) => {
  let response: any = {};
  loader.setDataIsLoading(true);

  try {
    response = await axiosInstance.delete(url);
  } catch (error: any) {
    response = error.response;
  } finally {
    loader.setDataIsLoading(false);
    if (response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    return response.data;
  }
};
