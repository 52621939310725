import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Header } from "./header";
import Loader from "./loader";
import { useEffect, useState } from "react";
import QuestionLayout from "./questionLayout";
export const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handalToggaleSidbar = () => {
    setIsOpen(!isOpen);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <Loader />
      <main
        className={
          isOpen ? "theme-01 sidebar-expand" : "theme-01 sidebar-collapse"
        }
      >
        <Header handalToggaleSidbar={handalToggaleSidbar} />
        <Aside isOpen={isOpen} />
        <main className="main">
          {location.pathname === "/question" ||
          location.pathname === "/question/view" ? (
            <QuestionLayout />
          ) : (
            <Outlet />
          )}
          <Footer />
        </main>
      </main>
    </>
  );
};
