import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AddUpdateUser } from "./addUpdate";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { Filter_Icon, close, filterIcon, plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect, useState, useRef } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import ReactSelect from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { StatusFilter } from "../../components/Filter/common";
import { getValueBykey } from "../../utils/generalUtils";
export const UserAPI = (data: any) => {
  return {
    ...useApi("admin/user", true, data, {}, false, true),
  };
};

function UserList(props: any) {
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedRole, setSelectedRole]: any = useState([]);
  const app = useApp();
  const validator = useRef(new SimpleReactValidator());
  const payload = {
    roleId: selectedRole,
    pagination: 1,
    isActive: status,
    status: status,
  };
  const isSuperAdmin = getValueBykey("roleName") === "Super Admin";
  const api = UserAPI(payload);
  const AddUpdate = useAddUpdate();
  const roleList: any = api.GetValue("/admin/roles");
  const ListOfRole = roleList?.data?.list?.map((item: any) => {
    return {
      value: item.id,
      label: item.roleName,
    };
  });
  const handleFilterToggle = () => {
    setFilter(!filter);
  };
  useEffect(() => {
    api.mutate();
    app.updateConversation("User");
  }, []);

  return AddUpdate.viewAddUpdate ? (
    <AddUpdateUser
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      validator={validator}
      value={AddUpdate.currentValue}
    />
  ) : (
    <PageLayout
      title="User list"
      item={
        <>
          <SearchData getSearchedData={api.getSearchedData} />
          <Button
            color="primary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                id: 0,
                name: "",
                emailId: "",
                mobileNo: "",
                roleId: "",
                userName: "",
              })
            }
          >
            {plus.icon}Add New
          </Button>
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <Card>
            <CardBody>
              <Row>
                <Col lg={12} className="filter_head">
                  <h6>{Filter_Icon.icon}Filter</h6>
                  <div className="d-flex justify-content-end gap-3">
                    <Button
                      className="clear_btn btn-primary"
                      color="link"
                      onClick={() => {
                        setSelectedRole([]);
                      }}
                    >
                      Clear all
                    </Button>
                    <Button
                      className="my-tooltip cancel_filter"
                      data-tooltip-content={"Close filter"}
                      onClick={handleFilterToggle}
                      color="link"
                    >
                      {close.icon}
                    </Button>
                  </div>
                </Col>
                <Col lg={4} className="mb-3">
                  <div className="form-floating sticky_label mb-0">
                    <ReactSelect
                      name="role"
                      onChange={(newValue: any) => {
                        if (newValue) {
                          setSelectedRole([newValue.value]);
                        } else {
                          setSelectedRole([]);
                        }
                      }}
                      isClearable={true}
                      options={ListOfRole}
                      value={ListOfRole?.filter(
                        (option: any) => option.value === selectedRole?.[0]
                      )}
                    />
                    <Label htmlFor="role">Role Name</Label>
                  </div>
                </Col>
                <Col lg={4}>
                  <StatusFilter setStatus={setStatus} status={status} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )
      }
      View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api?.data}
          updatePagination={api.updatePagination}
        />
      }
    />
  );
}

export default UserList;
