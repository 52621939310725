import Dashboard_soon from "../../src/assets/images/dashboard_coming_soon.png";
import { useLoaderHelper } from "../helper/loaderHelper";

const Dashboard = () => {
  return (
    <>
      <div className="no_data_found_img">
        <img src={Dashboard_soon} alt="dashboard" />
      </div>
    </>
  );
};
export default Dashboard;
