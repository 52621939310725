import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import SearchData from "../../components/Filter/search";
import { filterIcon } from "../../constant/icons";
import { Button } from "reactstrap";
import { CountryAPI } from "../../components/Filter/common";
import CountryFilter from "./filter";


function CountryList(props: any) {
  const app = useApp();
  const [status, setStatus] = useState(null);
  const payload = {
    status:status,
    pagination: 1,
    isActive: status,
  };
  const api = CountryAPI(payload);
  const AddUpdate = useAddUpdate();
  useEffect(() => {
    app.updateConversation("Country");
  }, []);
  const [filter, setFilter] = useState(true);
  const handleFilterToggle = () => {
    setFilter(!filter);
  };

  return (
    <PageLayout
      title="Country List"
      item={
        <>
          <SearchData getSearchedData={api.getSearchedData} />
          <Button color="link" onClick={handleFilterToggle}>
            {filterIcon.icon}
          </Button>
        </>
      }
      filter={
        filter && (
          <CountryFilter
            handleFilterToggle={handleFilterToggle}
            setStatus={setStatus}
            status={status}
          />
        )
      }
      View={
        <>
          <DataView
            columns={columns({
              api,
              openAddEditFrom: AddUpdate.openAddEditFrom,
            })}
            rows={api?.data}
            updatePagination={api.updatePagination}
          />
        </>
      }
    />
  );
}

export default CountryList;
