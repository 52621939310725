import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"; 
import { Label } from "reactstrap";
import { useFilterHelper } from "../../helper/filterHelper";
import Toolbar from "react-multi-date-picker/plugins/toolbar";

const DatePickerRange = (props: any) => {
  const { setSelectedDates, selectedDates } = useFilterHelper();
  const handleDateChange = (dates: DateObject[] | null, action: any) => {
    if (dates !== null) {
      const formattedDates: any = dates.map((date) =>
        date.format("DD/MM/YYYY")
      );
      if (formattedDates.length === 1) {
        setSelectedDates(formattedDates);
      } else if (formattedDates.length === 2) {
        setSelectedDates(formattedDates);
      }
    }
    if (action.validatedValue.length === 0) {
      setSelectedDates([]);
    }
  };
  return (
    <>
      <div className="form-floating sticky_label mb-0">
        <DatePicker
          value={selectedDates}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          inputClass={""}
          range
          plugins={[
            <Toolbar
              position="bottom"
              className={"hello"}
              names={{
                deselect: "Clear",
                today: "Today",
                close: "Close",
              }}
            />,
          ]}
          calendarPosition={"bottom"}
          placeholder="Select start date to end date"
        />
        <Label>Start-End Date</Label>
      </div>
    </>
  );
};

export default DatePickerRange;
