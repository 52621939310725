import { Card, CardBody, CardHeader, Col } from "reactstrap";
export const EmailTemapleType = (props: any) => {
  const { emailTemplateTypes, setTemplateId, templateId } = props;
  return (
    <>
      <Col md="3" className="Default_notifications">
        <Card>
          <CardHeader>
            <h4 className="card-title">Default Notifications</h4>
          </CardHeader>
          <CardBody>
            <ul>
              {emailTemplateTypes?.map((item: any, index: any) => (
                <li key={index}>
                  <a
                    className={`btn ${templateId === item?.id ? "active" : ""}`}
                    onClick={() => {
                      setTemplateId(item?.id);
                    }}
                  >
                    {item?.name}
                  </a>
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
