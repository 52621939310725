function PageLayout(props: any) {
  return (
    <div className="inner-page">
      <div className="section-head">
        <div>
          <h4>{props?.title}</h4>
        </div>
        <div className="header-action-item">{props?.item}</div>
      </div>
      {props?.filter && (
        <div className="filter-section mb-3">
          {props?.filter}
        </div>
      )}
      {props?.View}
    </div>
  );
}

export default PageLayout;
