import React from "react";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { KeyAPI } from "./list";


export default function Concept(props: any) {
  const { selectedValue, payload, search } = props;
  let api: any = [];
  
  if (selectedValue?.length > 0 || search) {
    api = KeyAPI({ ...payload, searchText: search });
  }

  return (
    <>
      <DataView
        columns={columns({ api })}
        rows={api?.data}
        updatePagination={api.updatePagination}
      />
    </>
  );
}
