import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Filter_Icon, advancefilterIcon, close } from "../../constant/icons";
import { useApi } from "../../helper/apiHook";
import {
  Conceptfilter,
  CountryFilter,
  Designationfilter,
  IndustryFilter,
  Jobfilter,
  Professionfilter,
  Skillfilter,
} from "../../components/Filter/common";
import { useFilterHelper } from "../../helper/filterHelper";
import DatePickerRange from "../../components/buttons/datePicker";

export default function QueFilter(props: any) {
  const {
    selectedValue,
    setSelectedValue,
    setSelectedKey,
    selectedSkill,
    setSelectedSkill,
    selectedKey,
    setSelectedIndustry,
    setSelectedProfession,
    selectedProfession,
    selectedIndustry,
    setSelectedJob,
    selectedJob,
    setSelectedDesignation,
    selectedDesignation,
    setSelectedDates,
    setTopicIds,
  } = useFilterHelper();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(false);
  const handleFilterToggle = () => {
    setFilter(!filter);
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} className="filter_head">
              <h6>{Filter_Icon.icon}Filter</h6>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  className="clear_btn btn-primary"
                  color="link"
                  onClick={() => {
                    setSelectedValue([]);
                    setSelectedKey([]);
                    setSelectedIndustry([]);
                    setSelectedProfession([]);
                    setSelectedSkill([]);
                    setSelectedJob([]);
                    setTopicIds([]);
                    setSelectedDesignation([]);
                    setSelectedDates([]);
                  }}
                >
                  Clear all
                </Button>
              </div>
            </Col>
            <Col lg={3} className="mb-3">
              <CountryFilter
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </Col>
            <Col lg={3}>
              <IndustryFilter
                selectedData={selectedValue}
                setSelectedIndustry={setSelectedIndustry}
                selectedIndustry={selectedIndustry}
                search={search}
                setSearch={setSearch}
                filterBody={{
                  countryId: selectedValue,
                }}
              />
            </Col>
            <Col lg={3}>
              <Professionfilter
                setSelectedProfession={setSelectedProfession}
                selectedProfession={selectedProfession}
                selectedData={selectedIndustry}
                search={search}
                setSearch={setSearch}
                filterBody={{
                  countryId: selectedValue,
                  industryId: selectedIndustry,
                }}
              />
            </Col>
            <Col lg={3}>
              <Skillfilter
                selectedSkill={selectedSkill}
                setSelectedSkill={setSelectedSkill}
                selectedData={selectedProfession}
                search={search}
                setSearch={setSearch}
                filterBody={{
                  countryId: selectedValue,
                  industryId: selectedIndustry,
                  professionId: selectedProfession,
                  jobId: selectedJob,
                }}
              />
            </Col>
            {/* <Col lg={3}>
              <Conceptfilter
                setSelectedKey={setSelectedKey}
                selectedKey={selectedKey}
                selectedData={selectedSkill}
                search={search}
                setSearch={setSearch}
                filterBody={{
                  countryId: selectedValue,
                  industryId: selectedIndustry,
                  professionId: selectedProfession,
                  skillId: selectedSkill,
                }}
              />
            </Col> */}

            <Col lg={12} className="d-flex justify-content-center pt-3">
              <Button
                color="link"
                className="advance_filter_btn"
                onClick={handleFilterToggle}
              >
                {advancefilterIcon.icon}
                Advanced Filter
              </Button>
            </Col>
            {filter && (
              <Col lg={12} className="advance_filter">
                <Row className="mt-3">
                  <Col lg={12} className="filter_head">
                    <h6>{advancefilterIcon.icon}Advanced Filter</h6>
                    <div className="d-flex justify-content-end gap-3">
                      <Button
                        className="my-tooltip cancel_filter"
                        data-tooltip-content={"Close filter"}
                        color="link"
                        onClick={handleFilterToggle}
                      >
                        {close.icon}
                      </Button>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <Designationfilter
                      selectedDesignation={selectedDesignation}
                      setSelectedDesignation={setSelectedDesignation}
                      selectedData={selectedKey}
                      search={search}
                      setSearch={setSearch}
                      filterBody={{
                        countryId: selectedValue,
                        industryId: selectedIndustry,
                        professionId: selectedProfession,
                        skillId: selectedSkill,
                        keyConceptId: selectedKey,
                        jobId: selectedJob,
                      }}
                    />
                  </Col> */}
                  <Col lg={3}>
                    <DatePickerRange />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export const ExpirenceApi = () => {
  return { ...useApi("admin/experience", false, { pagination: 0 }) };
};

export const ExpirenceFilter = (props: any) => {
  const { setExperience, experience } = props;
  const experienceData = ExpirenceApi();
  const array1to5 = experienceData?.data?.list.filter(
    (item: any) => item.experience >= 1 && item.experience <= 5
  );
  const array6to10 = experienceData?.data?.list
    .filter((item: any) => item.experience >= 6 && item.experience <= 10)
    .map((item: any) => item.id);
  const array11to15 = experienceData?.data?.list
    .filter((item: any) => item.experience >= 11 && item.experience <= 15)
    .map((item: any) => item.id);
  const array16to20 = experienceData?.data?.list
    .filter((item: any) => item.experience >= 16 && item.experience <= 20)
    .map((item: any) => item.id);
  return (
    <div className="experience_tab">
      <h3>Experience</h3>
      <ul>
        {array1to5?.map((d: any, id: string) => {
          return (
            <li key={id}>
              <Button
                color="link"
                className={experience.includes(d.id) ? "active" : ""}
                onClick={() => {
                  setExperience([d.id]);
                }}
              >
                <span>{d.experience}</span>Year
              </Button>
            </li>
          );
        })}
        {array6to10?.length > 0 && (
          <li>
            <Button
              color="link"
              className={
                array6to10?.every((item: any) => experience.includes(item))
                  ? "active"
                  : ""
              }
              onClick={() => {
                setExperience(array6to10);
              }}
            >
              <span>6-10</span>Year
            </Button>
          </li>
        )}
        {array11to15?.length > 0 && (
          <li>
            <Button
              color="link"
              className={
                array11to15?.every((item: any) => experience.includes(item))
                  ? "active"
                  : ""
              }
              onClick={() => {
                setExperience(array11to15);
              }}
            >
              <span>11-15</span>Year
            </Button>
          </li>
        )}
        {array16to20?.length > 0 && (
          <li>
            <Button
              color="link"
              className={
                array16to20?.every((item: any) => experience.includes(item))
                  ? "active"
                  : ""
              }
              onClick={() => {
                setExperience(array16to20);
              }}
            >
              <span>16-20</span>Year
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
};
