interface Icon {
  label: string;
  icon: any;
}
export const filterIcon: Icon = {
  label: "filter",
  icon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.66669"
        y="4"
        width="26.6667"
        height="12"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      ></rect>
      <path
        d="M21.3334 24V24C21.3334 24.62 21.3334 24.93 21.2652 25.1843C21.0803 25.8745 20.5412 26.4136 19.851 26.5985C19.5967 26.6667 19.2867 26.6667 18.6667 26.6667H13.3334C12.7134 26.6667 12.4034 26.6667 12.1491 26.5985C11.4589 26.4136 10.9198 25.8745 10.7348 25.1843C10.6667 24.93 10.6667 24.62 10.6667 24V24"
        stroke="currentColor"
        strokeWidth="2"
      ></path>
      <path
        d="M25.3334 18.6666V18.6666C25.3334 19.2866 25.3334 19.5966 25.2652 19.8509C25.0803 20.5411 24.5412 21.0802 23.851 21.2652C23.5967 21.3333 23.2867 21.3333 22.6667 21.3333H9.33335C8.71337 21.3333 8.40338 21.3333 8.14905 21.2652C7.45887 21.0802 6.91977 20.5411 6.73483 19.8509C6.66669 19.5966 6.66669 19.2866 6.66669 18.6666V18.6666"
        stroke="currentColor"
        strokeWidth="2"
      ></path>
    </svg>
  ),
};

export const company: Icon = {
  label: "company",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 22H5C3 22 2 21 2 19V11C2 9 3 8 5 8H10V19C10 21 11 22 13 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.11 4C10.03 4.3 10 4.63 10 5V8H5V6C5 4.9 5.9 4 7 4H10.11Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17H15C14.45 17 14 17.45 14 18V22H18V18C18 17.45 17.55 17 17 17Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13V17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19V5C10 3 11 2 13 2H19C21 2 22 3 22 5V19C22 21 21 22 19 22H13C11 22 10 21 10 19Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const convert: Icon = {
  label: "convert",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17.98L5.54999 17.99C6.45999 17.99 7.31 17.54 7.81 16.79L14.2 7.21C14.7 6.46 15.55 5.99999 16.46 6.00999L21.01 6.03001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 19.98L21 17.98"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89001 8.61999L7.81 7.11999C7.3 6.40999 6.47999 5.98999 5.60999 5.99999L3 6.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.97 15.38L14.19 16.95C14.7 17.61 15.5 18 16.34 18L21.01 17.98"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6.02002L19 4.02002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const close: Icon = {
  label: "close",
  icon: (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8807 4.13589C32.2693 3.73354 32.4843 3.19466 32.4794 2.6353C32.4746 2.07595 32.2502 1.54088 31.8547 1.14535C31.4591 0.74981 30.9241 0.525449 30.3647 0.520588C29.8054 0.515728 29.2665 0.730757 28.8641 1.11936L16.5057 13.4778L4.14734 1.11936C3.74499 0.730757 3.2061 0.515728 2.64675 0.520588C2.08739 0.525449 1.55233 0.74981 1.15679 1.14535C0.761254 1.54088 0.536893 2.07595 0.532032 2.6353C0.527172 3.19466 0.742201 3.73354 1.1308 4.13589L13.4892 16.4943L1.1308 28.8527C0.92705 29.0495 0.764527 29.2849 0.652721 29.5452C0.540915 29.8054 0.482065 30.0854 0.479603 30.3686C0.477142 30.6519 0.531118 30.9328 0.638384 31.195C0.74565 31.4572 0.904057 31.6954 1.10436 31.8957C1.30467 32.096 1.54286 32.2544 1.80504 32.3616C2.06721 32.4689 2.34813 32.5229 2.63139 32.5204C2.91466 32.518 3.19459 32.4591 3.45487 32.3473C3.71514 32.2355 3.95054 32.073 4.14734 31.8692L16.5057 19.5108L28.8641 31.8692C29.2665 32.2578 29.8054 32.4729 30.3647 32.468C30.9241 32.4631 31.4591 32.2388 31.8547 31.8432C32.2502 31.4477 32.4746 30.9126 32.4794 30.3533C32.4843 29.7939 32.2693 29.255 31.8807 28.8527L19.5223 16.4943L31.8807 4.13589Z"
        fill="currentColor"
      />
    </svg>
  ),
};
export const searchIcon: Icon = {
  label: "search",
  icon: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
    </svg>
  ),
};
export const connection: Icon = {
  label: "connection",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 6C21.1046 6 22 5.10457 22 4C22 2.89543 21.1046 2 20 2C18.8954 2 18 2.89543 18 4C18 5.10457 18.8954 6 20 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4H14C12 4 11 5 11 7V17C11 19 12 20 14 20H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
export const city: Icon = {
  label: "city",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none">
        <path
          fill="currentColor"
          d="M3.75 18a.75.75 0 0 0-1.5 0h1.5Zm-1.5-4a.75.75 0 0 0 1.5 0h-1.5ZM7 8.75c.964 0 1.612.002 2.095.067c.461.062.659.169.789.3l1.06-1.062c-.455-.455-1.022-.64-1.65-.725c-.606-.082-1.372-.08-2.294-.08v1.5ZM11.75 12c0-.922.002-1.688-.08-2.294c-.084-.628-.27-1.195-.726-1.65l-1.06 1.06c.13.13.237.328.3.79c.064.482.066 1.13.066 2.094h1.5ZM7 7.25c-.922 0-1.688-.002-2.294.08c-.628.084-1.195.27-1.65.725l1.06 1.061c.13-.13.328-.237.79-.3c.482-.064 1.13-.066 2.094-.066v-1.5ZM3.75 12c0-.964.002-1.612.067-2.095c.062-.461.169-.659.3-.789l-1.062-1.06c-.455.455-.64 1.022-.725 1.65c-.082.606-.08 1.372-.08 2.294h1.5Zm0 10v-4h-1.5v4h1.5Zm0-8v-2h-1.5v2h1.5Z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M7 22v-6c0-1.886 0-2.828.586-3.414C8.172 12 9.114 12 11 12h2c1.886 0 2.828 0 3.414.586c.472.471.564 1.174.582 2.414M17 22v-2.75m4-11.478c0-1.34 0-2.011-.356-2.525c-.356-.514-.984-.75-2.24-1.22c-2.455-.921-3.682-1.381-4.543-.785C13 3.84 13 5.15 13 7.772V12m8 10V12M4 8V6.5c0-.943 0-1.414.293-1.707C4.586 4.5 5.057 4.5 6 4.5h2c.943 0 1.414 0 1.707.293C10 5.086 10 5.557 10 6.5V8M7 4V2m15 20H2m8-7h.5m3.5 0h-1.5M10 18h4"
        />
      </g>
    </svg>
  ),
};
export const state: Icon = {
  label: "state",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8.33334C13.7073 8.33334 14.3856 8.05239 14.8857 7.55229C15.3858 7.05219 15.6667 6.37391 15.6667 5.66667C15.6667 4.95943 15.3858 4.28115 14.8857 3.78105C14.3856 3.28095 13.7073 3 13 3C12.2928 3 11.6145 3.28095 11.1144 3.78105C10.6143 4.28115 10.3334 4.95943 10.3334 5.66667C10.3334 6.37391 10.6143 7.05219 11.1144 7.55229C11.6145 8.05239 12.2928 8.33334 13 8.33334ZM13 4.33333C13.3537 4.33333 13.6928 4.47381 13.9429 4.72386C14.1929 4.97391 14.3334 5.31305 14.3334 5.66667C14.3334 6.02029 14.1929 6.35943 13.9429 6.60948C13.6928 6.85953 13.3537 7 13 7C12.6464 7 12.3073 6.85953 12.0572 6.60948C11.8072 6.35943 11.6667 6.02029 11.6667 5.66667C11.6667 5.31305 11.8072 4.97391 12.0572 4.72386C12.3073 4.47381 12.6464 4.33333 13 4.33333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.38134C7 9.94535 10.5373 16 13 16C15.464 16 19 9.94535 19 6.38134C19 2.86667 16.324 0 13 0C9.676 0 7 2.86667 7 6.38134ZM17.6667 6.38134C17.6667 9.30534 14.536 14.6667 13 14.6667C11.4653 14.6667 8.33333 9.30668 8.33333 6.38134C8.33333 3.58267 10.4333 1.33333 13 1.33333C15.5667 1.33333 17.6667 3.58267 17.6667 6.38134Z"
        fill="currentColor"
      />
      <path
        d="M6.5 12L4 10.5L1 21.5L6.5 25M6.5 25L13.5 23M6.5 25L7.5 15M13.5 23L20.5 25M13.5 23V20.5M20.5 25L26 21.5L22.5 10L19.5 12M20.5 25L19 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const country: Icon = {
  label: "country",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37346 9.33334C10.0807 9.33334 10.759 9.05239 11.2591 8.55229C11.7592 8.05219 12.0401 7.37391 12.0401 6.66667C12.0401 5.95943 11.7592 5.28115 11.2591 4.78105C10.759 4.28095 10.0807 4 9.37346 4C8.66621 4 7.98793 4.28095 7.48784 4.78105C6.98774 5.28115 6.70679 5.95943 6.70679 6.66667C6.70679 7.37391 6.98774 8.05219 7.48784 8.55229C7.98793 9.05239 8.66621 9.33334 9.37346 9.33334ZM9.37346 5.33333C9.72708 5.33333 10.0662 5.47381 10.3163 5.72386C10.5663 5.97391 10.7068 6.31305 10.7068 6.66667C10.7068 7.02029 10.5663 7.35943 10.3163 7.60948C10.0662 7.85953 9.72708 8 9.37346 8C9.01983 8 8.6807 7.85953 8.43065 7.60948C8.1806 7.35943 8.04012 7.02029 8.04012 6.66667C8.04012 6.31305 8.1806 5.97391 8.43065 5.72386C8.6807 5.47381 9.01983 5.33333 9.37346 5.33333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37341 6.38134C3.37341 9.94535 6.91075 16 9.37342 16C11.8374 16 15.3734 9.94535 15.3734 6.38134C15.3734 2.86667 12.6974 0 9.37342 0C6.04942 0 3.37341 2.86667 3.37341 6.38134ZM14.0401 6.38134C14.0401 9.30534 10.9094 14.6667 9.37342 14.6667C7.83875 14.6667 4.70675 9.30668 4.70675 6.38134C4.70675 3.58267 6.80675 1.33333 9.37342 1.33333C11.9401 1.33333 14.0401 3.58267 14.0401 6.38134Z"
        fill="currentColor"
      />
      <path
        d="M13.9534 10.1866C13.794 10.1181 13.6676 9.98999 13.6011 9.82969C13.5346 9.66938 13.5333 9.48946 13.5973 9.32817C13.6614 9.16688 13.7859 9.03692 13.9442 8.96593C14.1026 8.89494 14.2824 8.8885 14.4454 8.94798C14.9876 9.16303 15.4762 9.49405 15.877 9.91785C16.2778 10.3416 16.581 10.848 16.7654 11.4013L18.5441 16.7347C18.7447 17.3362 18.7994 17.9768 18.7037 18.6036C18.608 19.2304 18.3647 19.8255 17.9938 20.3398C17.6229 20.854 17.1351 21.2728 16.5705 21.5615C16.0059 21.8501 15.3808 22.0004 14.7468 22H4.00008C3.36623 22 2.74145 21.8494 2.17723 21.5605C1.61302 21.2717 1.12552 20.8529 0.754924 20.3387C0.384328 19.8245 0.141242 19.2295 0.0457011 18.6029C-0.0498398 17.9763 0.00489979 17.336 0.205408 16.7347L1.98274 11.4013C2.17041 10.838 2.48112 10.3236 2.89232 9.89535C3.30352 9.46708 3.8049 9.13572 4.36008 8.92531C4.44194 8.89423 4.52911 8.87958 4.61663 8.88219C4.70415 8.88481 4.7903 8.90463 4.87016 8.94053C4.95002 8.97644 5.02202 9.02772 5.08206 9.09146C5.1421 9.15519 5.189 9.23012 5.22008 9.31198C5.25116 9.39384 5.26581 9.48101 5.2632 9.56853C5.26059 9.65605 5.24076 9.7422 5.20486 9.82206C5.16895 9.90192 5.11767 9.97392 5.05394 10.034C4.99021 10.094 4.91527 10.1409 4.83341 10.172C4.46282 10.3122 4.12813 10.5332 3.85367 10.8189C3.57921 11.1047 3.37188 11.448 3.24675 11.824L1.46941 17.1573C1.33589 17.5582 1.29954 17.9851 1.36336 18.4028C1.42719 18.8205 1.58935 19.217 1.8365 19.5597C2.08364 19.9025 2.40869 20.1815 2.78486 20.374C3.16103 20.5665 3.57754 20.6668 4.00008 20.6667H14.7494C15.172 20.6666 15.5885 20.5662 15.9647 20.3735C16.3408 20.1809 16.6658 19.9017 16.9128 19.5588C17.1598 19.2159 17.3218 18.8193 17.3854 18.4015C17.4491 17.9838 17.4125 17.5568 17.2788 17.156L15.5028 11.8226C15.3797 11.4539 15.1775 11.1165 14.9103 10.8342C14.6431 10.5518 14.3175 10.3313 13.9561 10.188L13.9534 10.1866Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const users: Icon = {
  label: "users",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15957 10.87C9.05957 10.86 8.93957 10.86 8.82957 10.87C6.44957 10.79 4.55957 8.84 4.55957 6.44C4.55957 3.99 6.53957 2 8.99957 2C11.4496 2 13.4396 3.99 13.4396 6.44C13.4296 8.84 11.5396 10.79 9.15957 10.87Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4103 4C18.3503 4 19.9103 5.57 19.9103 7.5C19.9103 9.39 18.4103 10.93 16.5403 11C16.4603 10.99 16.3703 10.99 16.2803 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.15973 14.56C1.73973 16.18 1.73973 18.82 4.15973 20.43C6.90973 22.27 11.4197 22.27 14.1697 20.43C16.5897 18.81 16.5897 16.17 14.1697 14.56C11.4297 12.73 6.91973 12.73 4.15973 14.56Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const role: Icon = {
  label: "role",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 11C13.2867 11 14.3299 9.95681 14.3299 8.66998C14.3299 7.38316 13.2867 6.34003 11.9999 6.34003C10.7131 6.34003 9.66992 7.38316 9.66992 8.66998C9.66992 9.95681 10.7131 11 11.9999 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16.66C16 14.86 14.21 13.4 12 13.4C9.79 13.4 8 14.86 8 16.66"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const email: Icon = {
  label: "email",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16.5H8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.5H5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const tenant: Icon = {
  label: "tenant",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5007 22.0003H4.0807C2.9207 22.0003 1.9707 21.0703 1.9707 19.9303V5.09035C1.9707 2.47035 3.9207 1.28035 6.3107 2.45035L10.7507 4.63035C11.7107 5.10035 12.5007 6.35035 12.5007 7.41035V22.0003Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.97 15.0602V18.8402C21.97 21.0002 20.97 22.0002 18.81 22.0002H12.5V10.4202L12.97 10.5202L17.47 11.5302L19.5 11.9802C20.82 12.2702 21.9 12.9502 21.96 14.8702C21.97 14.9302 21.97 14.9902 21.97 15.0602Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 9.00012H8.97"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 13.0001H8.97"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4707 11.53V14.75C17.4707 15.99 16.4607 17 15.2207 17C13.9807 17 12.9707 15.99 12.9707 14.75V10.52L17.4707 11.53Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9607 14.8703C21.9007 16.0503 20.9207 17.0003 19.7207 17.0003C18.4807 17.0003 17.4707 15.9903 17.4707 14.7503V11.5303L19.5007 11.9803C20.8207 12.2703 21.9007 12.9503 21.9607 14.8703Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const hamburgerMenu: Icon = {
  label: "hamburger-menu",
  icon: (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 6H11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 11H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
};

export const home: Icon = {
  label: "home",
  icon: (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.75961C1 7.40185 1 6.72297 1.27446 6.12623C1.54892 5.52949 2.06437 5.08769 3.09525 4.20407L4.09525 3.34693C5.95857 1.7498 6.89023 0.951233 8 0.951233C9.10977 0.951233 10.0414 1.7498 11.9047 3.34693L12.9047 4.20407C13.9356 5.08769 14.4511 5.52949 14.7255 6.12623C15 6.72297 15 7.40185 15 8.75961V13C15 14.8856 15 15.8284 14.4142 16.4142C13.8284 17 12.8856 17 11 17H5C3.11438 17 2.17157 17 1.58579 16.4142C1 15.8284 1 14.8856 1 13V8.75961Z"
        stroke="currentColor"
      />
      <path
        d="M10.5 17V12C10.5 11.4477 10.0523 11 9.5 11H6.5C5.94772 11 5.5 11.4477 5.5 12V17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const logout: Icon = {
  label: "logout",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8999 7.56C9.2099 3.96 11.0599 2.49 15.1099 2.49H15.2399C19.7099 2.49 21.4999 4.28 21.4999 8.75V15.27C21.4999 19.74 19.7099 21.53 15.2399 21.53H15.1099C11.0899 21.53 9.2399 20.08 8.9099 16.54"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0001 12H3.62012"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.65L2.5 12L5.85 15.35"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const setting: Icon = {
  label: "setting",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.5H16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.5H2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 17.5H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17.5H2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const industry: Icon = {
  label: "industry",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 10c0-1-1-2-3-2h-1a3 3 0 0 1-3-3V2m7 19h3v-9h-3v4.5m0 4.5v-4.5m0 4.5H3v-4l3.5-3l4 2.5l4-2.5l3.5 2.5m3-6.5c0-6-4-6-4-6s4 .5 4-2"
      />
    </svg>
  ),
};
export const profession: Icon = {
  label: "profession",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z"
      />
    </svg>
  ),
};
export const job: Icon = {
  label: "job",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 1a1.75 1.75 0 0 0-1.75 1.75V4H3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.25V2.75A1.75 1.75 0 0 0 10 1H6Zm4.25 3V2.75A.25.25 0 0 0 10 2.5H6a.25.25 0 0 0-.25.25V4h4.5ZM3 5.5h10a.5.5 0 0 1 .5.5v1h-11V6a.5.5 0 0 1 .5-.5Zm-.5 3V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5H9V10H7V8.5H2.5Z"
        clipRule="evenodd"
      />
    </svg>
  ),
};
export const skill: Icon = {
  label: "skill",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        d="m31.707 19.293l-3-3a1 1 0 0 0-1.414 0L18 25.586V30h4.414l9.293-9.293a1 1 0 0 0 0-1.414zM21.586 28H20v-1.586l5-5L26.586 23l-5 5zM28 21.586L26.414 20L28 18.414L29.586 20L28 21.586zM16 22c-3.364 0-6-2.636-6-6s2.636-6 6-6s6 2.636 6 6s-2.636 6-6 6zm0-10c-2.28 0-4 1.72-4 4s1.72 4 4 4s4-1.72 4-4s-1.72-4-4-4z"
      />
      <path
        fill="currentColor"
        d="m27.547 12l1.733-1l-2.336-4.044a2 2 0 0 0-2.373-.894l-2.434.823a11.056 11.056 0 0 0-1.312-.758l-.503-2.52A2 2 0 0 0 18.36 2h-4.72a2 2 0 0 0-1.962 1.608l-.503 2.518c-.46.225-.906.47-1.327.754l-2.42-.818a2 2 0 0 0-2.373.894l-2.36 4.088a2 2 0 0 0 .412 2.502l1.931 1.697C5.021 15.495 5 15.745 5 16c0 .258.01.513.028.766l-1.92 1.688a2 2 0 0 0-.413 2.502l2.36 4.088a2 2 0 0 0 2.374.894l2.434-.823c.418.282.856.535 1.312.758l.503 2.519A2 2 0 0 0 13.64 30H16v-2h-2.36l-.71-3.55a9.096 9.096 0 0 1-2.695-1.572l-3.447 1.166l-2.36-4.088l2.725-2.395a8.929 8.929 0 0 1-.007-3.128l-2.719-2.39l2.361-4.087l3.427 1.16A9.027 9.027 0 0 1 12.93 7.55L13.64 4h4.721l.71 3.55a9.1 9.1 0 0 1 2.695 1.572l3.447-1.166L27.547 12Z"
      />
    </svg>
  ),
};
export const designation: Icon = {
  label: "designation",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        fill="currentColor"
        d="M2 12.5v.5h1v-.5H2Zm5 0v.5h1v-.5H7Zm-4 0V12H2v.5h1Zm4-.5v.5h1V12H7Zm-2-2a2 2 0 0 1 2 2h1a3 3 0 0 0-3-3v1Zm-2 2a2 2 0 0 1 2-2V9a3 3 0 0 0-3 3h1Zm2-8a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1V4Zm2 2a2 2 0 0 0-2-2v1a1 1 0 0 1 1 1h1ZM5 8a2 2 0 0 0 2-2H6a1 1 0 0 1-1 1v1Zm0-1a1 1 0 0 1-1-1H3a2 2 0 0 0 2 2V7ZM1.5 3h12V2h-12v1Zm12.5.5v8h1v-8h-1Zm-.5 8.5h-12v1h12v-1ZM1 11.5v-8H0v8h1Zm.5.5a.5.5 0 0 1-.5-.5H0A1.5 1.5 0 0 0 1.5 13v-1Zm12.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1ZM13.5 3a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 13.5 2v1Zm-12-1A1.5 1.5 0 0 0 0 3.5h1a.5.5 0 0 1 .5-.5V2ZM9 6h3V5H9v1Zm0 3h3V8H9v1Z"
      />
    </svg>
  ),
};
export const topic: Icon = {
  label: "topic",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="4"
      >
        <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24a19.94 19.94 0 0 0 3.368 11.112c.244.363-.213 2.66-1.368 6.888c4.229-1.155 6.525-1.612 6.888-1.368A19.94 19.94 0 0 0 24 44Z" />
        <path
          strokeLinecap="round"
          d="M16.605 19.82h16.779m-11.537-4.082l-2.914 16.524m9.914-16.524l-2.914 16.524M14.604 28h16.78"
        />
      </g>
    </svg>
  ),
};
export const question_menu: Icon = {
  label: "question_menu",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4C12.954 4 4.00001 12.954 4.00001 24C3.99551 27.9561 5.16789 31.8241 7.36801 35.112C7.61201 35.475 7.15501 37.772 6.00001 42C10.229 40.845 12.525 40.388 12.888 40.632C16.1759 42.8321 20.0439 44.0045 24 44Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M19 17.5714C19 16.4695 19.3268 15.3923 19.939 14.4761C20.5512 13.5599 21.4213 12.8458 22.4393 12.4241C23.4574 12.0024 24.5776 11.8921 25.6584 12.1071C26.7391 12.322 27.7318 12.8527 28.511 13.6318C29.2902 14.411 29.8208 15.4037 30.0358 16.4845C30.2508 17.5652 30.1404 18.6855 29.7188 19.7035C29.2971 20.7216 28.583 21.5917 27.6667 22.2039C26.7505 22.8161 25.6734 23.1429 24.5714 23.1429V26.8571"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5714 32.4285C24.0204 32.4285 23.4818 32.5918 23.0237 32.8979C22.5656 33.204 22.2085 33.6391 21.9977 34.1481C21.7869 34.6572 21.7317 35.2173 21.8392 35.7576C21.9467 36.298 22.212 36.7944 22.6016 37.184C22.9912 37.5736 23.4875 37.8389 24.0279 37.9464C24.5683 38.0539 25.1284 37.9987 25.6374 37.7878C26.1464 37.577 26.5815 37.2199 26.8876 36.7618C27.1937 36.3037 27.3571 35.7651 27.3571 35.2142C27.3475 34.4784 27.0509 33.7754 26.5305 33.255C26.0102 32.7347 25.3072 32.4381 24.5714 32.4285Z"
        fill="currentColor"
      />
    </svg>
  ),
};
export const arrowRight: Icon = {
  label: "arrowRight",
  icon: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4299 0.929993L18.4999 6.99999L12.4299 13.07"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 7H18.33"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const longarrowRight: Icon = {
  label: "longarrowRight",
  icon: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4299 0.929993L18.4999 6.99999L12.4299 13.07"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 7H18.33"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const longarrowLeft: Icon = {
  label: "longarrowLeft",
  icon: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57 0.929993L1.5 6.99999L7.57 13.07"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4999 7H1.66992"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const profileDuotone: Icon = {
  label: "profileDuotone",
  icon: (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z"
        fill="currentColor"
      />
      <path
        d="M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z"
        fill="currentColor"
      />
      <path
        d="M18.7807 19.36C17.0007 21 14.6207 22.01 12.0007 22.01C9.3807 22.01 7.0007 21 5.2207 19.36C5.4607 18.45 6.1107 17.62 7.0607 16.98C9.7907 15.16 14.2307 15.16 16.9407 16.98C17.9007 17.62 18.5407 18.45 18.7807 19.36Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const keyConcept: Icon = {
  label: "keyConcept",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        d="M20 3c-4.945 0-9 4.055-9 9c0 .52.086.977.156 1.438L3.281 21.28L3 21.594V29h7v-3h3v-3h3v-2.969c1.18.578 2.555.969 4 .969c4.945 0 9-4.055 9-9s-4.055-9-9-9zm0 2c3.855 0 7 3.145 7 7s-3.145 7-7 7a7.356 7.356 0 0 1-3.406-.875l-.25-.125H14v3h-3v3H8v3H5v-4.563l7.906-7.937l.375-.344l-.094-.531C13.086 13.023 13 12.488 13 12c0-3.855 3.145-7 7-7zm2 3a1.999 1.999 0 1 0 0 4a1.999 1.999 0 1 0 0-4z"
      />
    </svg>
  ),
};

export const logoutDuotone: Icon = {
  label: "logoutDuotone",
  icon: (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M15.9998 2L14.9998 2C12.1714 2 10.7576 2.00023 9.87891 2.87891C9.00023 3.75759 9.00023 5.1718 9.00023 8.00023L9.00023 16.0002C9.00023 18.8287 9.00023 20.2429 9.87891 21.1215C10.7574 22 12.1706 22 14.9976 22L14.9998 22L15.9998 22C18.8282 22 20.2424 22 21.1211 21.1213C21.9998 20.2426 21.9998 18.8284 21.9998 16L21.9998 8L21.9998 7.99998C21.9998 5.17157 21.9998 3.75736 21.1211 2.87868C20.2424 2 18.8282 2 15.9998 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 12C15.75 11.5858 15.4142 11.25 15 11.25L4.02744 11.25L5.98809 9.56943C6.30259 9.29986 6.33901 8.82639 6.06944 8.51189C5.79988 8.1974 5.3264 8.16098 5.01191 8.43054L1.51191 11.4305C1.34567 11.573 1.25 11.781 1.25 12C1.25 12.2189 1.34567 12.4269 1.51191 12.5694L5.01191 15.5694C5.3264 15.839 5.79988 15.8026 6.06944 15.4881C6.33901 15.1736 6.30259 14.7001 5.98809 14.4305L4.02744 12.75L15 12.75C15.4142 12.75 15.75 12.4142 15.75 12Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const edit: Icon = {
  label: "editIcon",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2603 3.60022L5.05034 12.2902C4.74034 12.6202 4.44034 13.2702 4.38034 13.7202L4.01034 16.9602C3.88034 18.1302 4.72034 18.9302 5.88034 18.7302L9.10034 18.1802C9.55034 18.1002 10.1803 17.7702 10.4903 17.4302L18.7003 8.74022C20.1203 7.24022 20.7603 5.53022 18.5503 3.44022C16.3503 1.37022 14.6803 2.10022 13.2603 3.60022Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8896 5.0498C12.3196 7.8098 14.5596 9.9198 17.3396 10.1998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 22H21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const editSolid: Icon = {
  label: "editSolid",
  icon: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.204 7.79601L16 6C16.5453 5.45475 16.8179 5.18213 16.9636 4.88803C17.2409 4.32848 17.2409 3.67153 16.9636 3.11197C16.8179 2.81788 16.5453 2.54525 16 2C15.4548 1.45475 15.1821 1.18213 14.888 1.03639C14.3285 0.75911 13.6715 0.75911 13.112 1.03639C12.8179 1.18213 12.5453 1.45475 12 2L10.1814 3.81866C11.1452 5.46926 12.5314 6.84482 14.204 7.79601ZM8.7269 5.27312L1.8564 12.1436C1.43134 12.5687 1.21881 12.7812 1.07907 13.0423C0.939336 13.3034 0.88039 13.5981 0.7625 14.1876L0.147104 17.2646C0.0805817 17.5972 0.0473206 17.7635 0.141929 17.8581C0.236537 17.9527 0.402842 17.9194 0.735452 17.8529L3.81243 17.2375C4.40189 17.1196 4.69661 17.0607 4.95771 16.9209C5.21881 16.7812 5.43134 16.5687 5.8564 16.1436L12.7458 9.25422C11.1241 8.23857 9.75244 6.87628 8.7269 5.27312Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const plus: Icon = {
  label: "plus",
  icon: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13V1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const arrowLeft: Icon = {
  label: "arrowLeft",
  icon: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57 0.929993L1.5 6.99999L7.57 13.07"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4999 7H1.66992"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const leftArrow: Icon = {
  label: "leftArrow",
  icon: (
    <svg
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00009 16.92L1.48009 10.4C0.710088 9.62999 0.710088 8.36999 1.48009 7.59999L8.00009 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const doubleleftArrow: Icon = {
  label: "doubleleftArrow",
  icon: (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00009 16.92L1.48009 10.4C0.710088 9.62999 0.710088 8.36999 1.48009 7.59999L8.00009 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0001 16.92L9.48009 10.4C8.71009 9.62999 8.71009 8.36999 9.48009 7.59999L16.0001 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const rightArrow: Icon = {
  label: "rightArrow",
  icon: (
    <svg
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.909912 16.92L7.42991 10.4C8.19991 9.62999 8.19991 8.36999 7.42991 7.59999L0.909912 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const doublerightArrow: Icon = {
  label: "doublerightArrow",
  icon: (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90991 16.92L15.4299 10.4C16.1999 9.62999 16.1999 8.36999 15.4299 7.59999L8.90991 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.909912 16.92L7.42991 10.4C8.19991 9.62999 8.19991 8.36999 7.42991 7.59999L0.909912 1.07999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const deleteIcon: Icon = {
  label: "deleteIcon",
  icon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5001 4H1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.5 9L8 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 9L12 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.5 4C4.55588 4 4.58382 4 4.60915 3.99936C5.43259 3.97849 6.15902 3.45491 6.43922 2.68032C6.44784 2.65649 6.45667 2.62999 6.47434 2.57697L6.57143 2.28571C6.65431 2.03708 6.69575 1.91276 6.75071 1.8072C6.97001 1.38607 7.37574 1.09364 7.84461 1.01877C7.96213 1 8.09317 1 8.35526 1H11.6447C11.9068 1 12.0379 1 12.1554 1.01877C12.6243 1.09364 13.03 1.38607 13.2493 1.8072C13.3043 1.91276 13.3457 2.03708 13.4286 2.28571L13.5257 2.57697C13.5433 2.62992 13.5522 2.65651 13.5608 2.68032C13.841 3.45491 14.5674 3.97849 15.3909 3.99936C15.4162 4 15.4441 4 15.5 4"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M16.3735 13.3991C16.1965 16.054 16.108 17.3815 15.243 18.1907C14.378 19 13.0476 19 10.3868 19H9.6134C6.9526 19 5.6222 19 4.75719 18.1907C3.89218 17.3815 3.80368 16.054 3.62669 13.3991L3.16675 6.5M16.8334 6.5L16.2974 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
};

export const briefcase: Icon = {
  label: "briefcase",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99983 22H15.9998C20.0198 22 20.7398 20.39 20.9498 18.43L21.6998 10.43C21.9698 7.99 21.2698 6 16.9998 6H6.99983C2.72983 6 2.02983 7.99 2.29983 10.43L3.04983 18.43C3.25983 20.39 3.97983 22 7.99983 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.62012 11.27C4.87012 12.81 7.41012 13.74 10.0001 14.03"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const clock: Icon = {
  label: "clock",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="10" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M11 7V11L13.5 13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const cloud: Icon = {
  label: "cloud",
  icon: (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26906 10.0098C5.73906 9.73976 5.14906 9.59976 4.54906 9.59976C-0.130938 9.92976 -0.130938 16.7398 4.54906 17.0698H15.6391C16.9891 17.0798 18.2891 16.5798 19.2791 15.6698C22.5691 12.7998 20.8091 7.02976 16.4791 6.47976C14.9191 -2.89024 1.38906 0.669757 4.59906 9.59976"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8496 6.91977C15.3696 6.65977 15.9396 6.51977 16.5196 6.50977"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const danger: Icon = {
  label: "danger",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 8V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9994 20.4103H4.93944C1.46944 20.4103 0.0194446 17.9303 1.69944 14.9003L4.81944 9.28027L7.75944 4.00027C9.53944 0.790273 12.4594 0.790273 14.2394 4.00027L17.1794 9.29027L20.2994 14.9103C21.9794 17.9403 20.5194 20.4203 17.0594 20.4203H10.9994V20.4103Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9941 16H11.0031"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const date: Icon = {
  label: "cloud",
  icon: (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1V4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 1V4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 7.5V16C19 19 17.5 21 14 21H6C2.5 21 1 19 1 16V7.5C1 4.5 2.5 2.5 6 2.5H14C17.5 2.5 19 4.5 19 7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10H14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const right: Icon = {
  label: "right",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 11L9.58 13.83L15.25 8.17001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

// Other icons out from library
export const longBoldArrowRight: Icon = {
  label: "longBoldArrowRight",
  icon: (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7L16.7071 6.29289L17.4142 7L16.7071 7.70711L16 7ZM1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6V8ZM10.7071 0.292893L16.7071 6.29289L15.2929 7.70711L9.29289 1.70711L10.7071 0.292893ZM16.7071 7.70711L10.7071 13.7071L9.29289 12.2929L15.2929 6.29289L16.7071 7.70711ZM16 8H1V6H16V8Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const folderCloud: Icon = {
  label: "folderCloud",
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44001 10.9 3.20001L12.4 5.20001C12.78 5.70001 13 6 14 6H17C21 6 22 7 22 11V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7605 18.3199C11.4105 18.4899 11.4105 21.8899 13.7605 22.0599H19.3205C19.9905 22.0599 20.6506 21.8099 21.1406 21.3599C22.7906 19.9199 21.9105 17.0399 19.7405 16.7699C18.9605 12.0799 12.1806 13.8599 13.7806 18.3299"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export const file: Icon = {
  label: "file",
  icon: (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M12.4286 6.71429V1H5C3.11438 1 2.17157 1 1.58579 1.58579C1 2.17157 1 3.11438 1 5V22.7143C1 24.5999 1 25.5427 1.58579 26.1285C2.17157 26.7143 3.11438 26.7143 5 26.7143H17C18.8856 26.7143 19.8284 26.7143 20.4142 26.1285C21 25.5427 21 24.5999 21 22.7143V9.57143H15.2857C13.9388 9.57143 13.2654 9.57143 12.847 9.15301C12.4286 8.73459 12.4286 8.06116 12.4286 6.71429Z"
        fill="currentColor"
        fill-opacity="0.25"
      />{" "}
      <path
        d="M12.6737 1H6.71429C4.02055 1 2.67368 1 1.83684 1.83684C1 2.67368 1 4.02055 1 6.71429V21C1 23.6937 1 25.0406 1.83684 25.8774C2.67368 26.7143 4.02055 26.7143 6.71429 26.7143H15.2857C17.9795 26.7143 19.3263 26.7143 20.1632 25.8774C21 25.0406 21 23.6937 21 21V9.32632C21 8.74239 21 8.45043 20.8913 8.1879C20.7825 7.92537 20.5761 7.71892 20.1632 7.30602L14.694 1.83684C14.2811 1.42394 14.0746 1.21749 13.8121 1.10874C13.5496 1 13.2576 1 12.6737 1Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />{" "}
      <path
        d="M6.71429 15.2858L15.2857 15.2858"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M6.71429 21L12.4286 21"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M12.4286 1V6.71429C12.4286 8.06116 12.4286 8.73459 12.847 9.15301C13.2654 9.57143 13.9388 9.57143 15.2857 9.57143H21"
        stroke="currentColor"
        strokeWidth="1.2"
      />{" "}
    </svg>
  ),
};

export const fileupload: Icon = {
  label: "fileupload",
  icon: (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <rect
        x="1"
        y="1"
        width="17.5"
        height="22.5"
        rx="2"
        fill="currentColor"
        fill-opacity="0.25"
      />{" "}
      <path
        d="M4.75 14.75L12.25 14.75"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M4.75 11L9.75 11"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M4.75 18.5L9.75 18.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M18.5 13.5V17.5C18.5 20.3284 18.5 21.7426 17.6213 22.6213C16.7426 23.5 15.3284 23.5 12.5 23.5H7C4.17157 23.5 2.75736 23.5 1.87868 22.6213C1 21.7426 1 20.3284 1 17.5V7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H9.75"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M17.25 1L17.25 8.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
      <path
        d="M21 4.75L13.5 4.75"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />{" "}
    </svg>
  ),
};

export const equalicon: Icon = {
  label: "equal",
  icon: (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M0.169127 1.5999C0.169127 1.29284 0.291105 0.998361 0.508227 0.781239C0.725349 0.564117 1.01983 0.442139 1.32689 0.442139H12.9753C13.276 0.45151 13.5612 0.577536 13.7705 0.79353C13.9799 1.00952 14.097 1.29853 14.097 1.59934C14.097 1.90015 13.9799 2.18915 13.7705 2.40514C13.5612 2.62114 13.276 2.74716 12.9753 2.75653H1.32576C1.01909 2.75594 0.725192 2.6337 0.508555 2.41664C0.291919 2.19958 0.17025 1.90544 0.170251 1.59877L0.169127 1.5999ZM14.1319 6.77497C14.1319 7.08203 14.01 7.37651 13.7928 7.59363C13.5757 7.81075 13.2812 7.93273 12.9742 7.93273H1.32689C1.17189 7.93756 1.0175 7.91121 0.872877 7.85522C0.728258 7.79924 0.59636 7.71478 0.48501 7.60685C0.37366 7.49891 0.285129 7.36971 0.22467 7.22691C0.164211 7.0841 0.133057 6.93061 0.133057 6.77553C0.133057 6.62046 0.164211 6.46696 0.22467 6.32416C0.285129 6.18136 0.37366 6.05215 0.48501 5.94422C0.59636 5.83629 0.728258 5.75182 0.872877 5.69584C1.0175 5.63986 1.17189 5.6135 1.32689 5.61834H12.9764C13.2833 5.61863 13.5775 5.74074 13.7944 5.95783C14.0112 6.17492 14.1331 6.46923 14.1331 6.7761L14.1319 6.77497Z"
        fill="currentColor"
      />{" "}
    </svg>
  ),
};

export const reset: Icon = {
  label: "reset",
  icon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0V2.22222C14.2878 2.22222 17.7778 5.71111 17.7778 10C17.7778 14.2889 14.2878 17.7778 10 17.7778C5.71222 17.7778 2.22222 14.2889 2.22222 10C2.22222 7.94778 3.03889 6.01555 4.44445 4.57555V7.22222H6.66667V1.11111H0.555552V3.33333H2.54555C0.923333 5.14667 0 7.51 0 10C0 15.5133 4.48555 20 10 20C15.5144 20 20 15.5133 20 10C20 4.48667 15.5144 0 10 0Z"
        fill="currentColor"
      />{" "}
    </svg>
  ),
};

export const saveMapping: Icon = {
  label: "savemaping",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M15 7.98999V19.35C15 20.8 13.96 21.41 12.69 20.71L8.76001 18.52C8.34001 18.29 7.65999 18.29 7.23999 18.52L3.31 20.71C2.04 21.41 1 20.8 1 19.35V7.98999C1 6.27999 2.39999 4.88 4.10999 4.88H11.89C13.6 4.88 15 6.27999 15 7.98999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M21 4.10999V15.47C21 16.92 19.96 17.53 18.69 16.83L15 14.77V7.98999C15 6.27999 13.6 4.88 11.89 4.88H7V4.10999C7 2.39999 8.39999 1 10.11 1H17.89C19.6 1 21 2.39999 21 4.10999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M6 11H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M8 13V9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const copyToClipboard: Icon = {
  label: "copyToClipboard",
  icon: (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const errorIcon: Icon = {
  label: "erroricon",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M11 8V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M10.9994 20.4103H4.93944C1.46944 20.4103 0.0194446 17.9303 1.69944 14.9003L4.81944 9.28027L7.75944 4.00027C9.53944 0.790273 12.4594 0.790273 14.2394 4.00027L17.1794 9.29027L20.2994 14.9103C21.9794 17.9403 20.5194 20.4203 17.0594 20.4203H10.9994V20.4103Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M10.9941 16H11.0031"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const successIcon: Icon = {
  label: "successicon",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M7.375 10.9925L9.785 13.4125L14.615 8.57251"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M9.745 1.4425C10.435 0.8525 11.565 0.8525 12.265 1.4425L13.845 2.8025C14.145 3.0625 14.705 3.2725 15.105 3.2725H16.805C17.865 3.2725 18.735 4.1425 18.735 5.2025V6.9025C18.735 7.2925 18.945 7.8625 19.205 8.1625L20.565 9.7425C21.155 10.4325 21.155 11.5625 20.565 12.2625L19.205 13.8425C18.945 14.1425 18.735 14.7025 18.735 15.1025V16.8025C18.735 17.8625 17.865 18.7325 16.805 18.7325H15.105C14.715 18.7325 14.145 18.9425 13.845 19.2025L12.265 20.5625C11.575 21.1525 10.445 21.1525 9.745 20.5625L8.165 19.2025C7.865 18.9425 7.305 18.7325 6.905 18.7325H5.175C4.115 18.7325 3.245 17.8625 3.245 16.8025V15.0925C3.245 14.7025 3.035 14.1425 2.785 13.8425L1.435 12.2525C0.855 11.5625 0.855 10.4425 1.435 9.7525L2.785 8.1625C3.035 7.8625 3.245 7.3025 3.245 6.9125V5.1925C3.245 4.1325 4.115 3.2625 5.175 3.2625H6.905C7.295 3.2625 7.865 3.0525 8.165 2.7925L9.745 1.4425Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const documentIcon: Icon = {
  label: "documenticon",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M21 9V14C21 19 19 21 14 21H8C3 21 1 19 1 14V8C1 3 3 1 8 1H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M21 9H17C14 9 13 8 13 5V1L21 9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M6 12H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M6 16H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const sheetIcon: Icon = {
  label: "sheeticon",
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M11.3701 7.88H16.6201"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M5.37988 7.88L6.12988 8.63L8.37988 6.38"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M11.3701 14.88H16.6201"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M5.37988 14.88L6.12988 15.63L8.37988 13.38"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const downloadIcon: Icon = {
  label: "downloadicon",
  icon: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M6.32007 8.68005L8.88007 11.2401L11.4401 8.68005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M8.87988 1V11.17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M17 9.18005C17 13.6001 14 17.1801 9 17.1801C4 17.1801 1 13.6001 1 9.18005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  ),
};

export const detailInfo: Icon = {
  label: "detailinfo",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.5 7.91667C9.70996 7.91667 9.91133 8.00007 10.0598 8.14854C10.2083 8.29701 10.2917 8.49837 10.2917 8.70833V13.4583C10.2917 13.6683 10.2083 13.8697 10.0598 14.0181C9.91133 14.1666 9.70996 14.25 9.5 14.25C9.29004 14.25 9.08867 14.1666 8.94021 14.0181C8.79174 13.8697 8.70833 13.6683 8.70833 13.4583V8.70833C8.70833 8.49837 8.79174 8.29701 8.94021 8.14854C9.08867 8.00007 9.29004 7.91667 9.5 7.91667ZM9.5 6.72917C9.65594 6.72917 9.81036 6.69845 9.95444 6.63877C10.0985 6.5791 10.2294 6.49163 10.3397 6.38136C10.45 6.27109 10.5374 6.14018 10.5971 5.9961C10.6568 5.85203 10.6875 5.69761 10.6875 5.54167C10.6875 5.38572 10.6568 5.2313 10.5971 5.08723C10.5374 4.94316 10.45 4.81225 10.3397 4.70198C10.2294 4.59171 10.0985 4.50424 9.95444 4.44456C9.81036 4.38488 9.65594 4.35417 9.5 4.35417C9.18548 4.35417 8.88383 4.47911 8.66143 4.70151C8.43903 4.92392 8.31408 5.22556 8.31408 5.54008C8.31408 5.85461 8.43903 6.15625 8.66143 6.37865C8.88383 6.60106 9.18548 6.726 9.5 6.726V6.72917ZM0 9.5C-1.85901e-08 8.25244 0.245725 7.0171 0.723144 5.86451C1.20056 4.71191 1.90033 3.66464 2.78249 2.78249C3.66464 1.90033 4.71191 1.20056 5.86451 0.723145C7.0171 0.245725 8.25244 0 9.5 0C10.7476 0 11.9829 0.245725 13.1355 0.723145C14.2881 1.20056 15.3354 1.90033 16.2175 2.78249C17.0997 3.66464 17.7994 4.71191 18.2769 5.86451C18.7543 7.0171 19 8.25244 19 9.5C19 12.0196 17.9991 14.4359 16.2175 16.2175C14.4359 17.9991 12.0196 19 9.5 19C6.98044 19 4.56408 17.9991 2.78249 16.2175C1.00089 14.4359 3.75443e-08 12.0196 0 9.5ZM9.5 1.58333C7.40037 1.58333 5.38673 2.41741 3.90207 3.90207C2.41741 5.38673 1.58333 7.40037 1.58333 9.5C1.58333 11.5996 2.41741 13.6133 3.90207 15.0979C5.38673 16.5826 7.40037 17.4167 9.5 17.4167C11.5996 17.4167 13.6133 16.5826 15.0979 15.0979C16.5826 13.6133 17.4167 11.5996 17.4167 9.5C17.4167 7.40037 16.5826 5.38673 15.0979 3.90207C13.6133 2.41741 11.5996 1.58333 9.5 1.58333Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const connectIcon: Icon = {
  label: "connecticon",
  icon: (
    <svg
      fill="currentColor"
      height="800px"
      width="800px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.997 511.997"
    >
      <path
        d="M212.26,390.24l-60.331,60.331c-25.012,25.012-65.517,25.012-90.508,0.005c-24.996-24.996-24.996-65.505-0.005-90.496
          l120.683-120.683c24.991-24.992,65.5-24.992,90.491,0c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17
          c-41.654-41.654-109.177-41.654-150.831,0L31.247,329.909c-41.654,41.654-41.654,109.177,0,150.831
          c41.649,41.676,109.177,41.676,150.853,0l60.331-60.331c8.331-8.331,8.331-21.839,0-30.17S220.591,381.909,212.26,390.24z"
      />
      <path
        d="M480.751,31.24c-41.654-41.654-109.199-41.654-150.853,0l-72.384,72.384c-8.331,8.331-8.331,21.839,0,30.17
          c8.331,8.331,21.839,8.331,30.17,0l72.384-72.384c24.991-24.992,65.521-24.992,90.513,0c24.991,24.991,24.991,65.5,0,90.491
          L317.845,284.638c-24.992,24.992-65.5,24.992-90.491,0c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17
          c41.654,41.654,109.177,41.654,150.831,0l132.736-132.736C522.405,140.418,522.405,72.894,480.751,31.24z"
      />
    </svg>
  ),
};

export const viewIcon: Icon = {
  label: "viewIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
    >
      <circle cx="10" cy="7" r="3.5" stroke="currentColor" />
      <path
        d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36427 13 3.23206 9.78986 1.81197 8.06571C1.42381 7.59444 1.22973 7.35881 1.22973 7C1.22973 6.64119 1.42381 6.40556 1.81197 5.93429C3.23206 4.21014 6.36427 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z"
        stroke="currentColor"
      />
    </svg>
  ),
};

export const saveIcon: Icon = {
  label: "saveIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H13C13.7956 0 14.5587 0.316071 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3V19.028C16 20.248 14.62 20.958 13.628 20.249L8 16.229L2.372 20.249C1.379 20.959 0 20.249 0 19.029V3ZM3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V18.057L7.128 14.394C7.38243 14.2122 7.68731 14.1145 8 14.1145C8.31269 14.1145 8.61757 14.2122 8.872 14.394L14 18.057V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H3Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export const rightIcon: Icon = {
  label: "rightIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
    >
      <path
        d="M1.71754 9.61518L7.29725 14.8978C7.68723 15.267 8.29921 15.2622 8.68343 14.887L22.0168 1.86639"
        stroke="currentColor"
        strokeWidth="2.2"
        strokeLinecap="round"
      />
    </svg>
  ),
};

export const clickIcon: Icon = {
  label: "clickIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M9.71 15.99C8.17063 15.9172 6.71846 15.2542 5.65514 14.1388C4.59181 13.0233 3.99905 11.5411 4 10C4 6.69 6.69 4 10 4C13.22 4 15.84 6.53 15.99 9.71L13.89 9.08C13.7266 8.38678 13.3809 7.74979 12.8889 7.23488C12.3968 6.71996 11.7761 6.34581 11.091 6.15108C10.4059 5.95636 9.68128 5.94814 8.99195 6.12728C8.30262 6.30641 7.67363 6.66639 7.17001 7.17001C6.66639 7.67363 6.30641 8.30262 6.12728 8.99195C5.94814 9.68128 5.95636 10.4059 6.15108 11.091C6.34581 11.7761 6.71996 12.3968 7.23488 12.8889C7.74979 13.3809 8.38678 13.7266 9.08 13.89L9.71 15.99ZM20 10C20 10.3 19.99 10.6 19.96 10.9L17.99 10.31C18 10.21 18 10.1 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C10.1 18 10.21 18 10.31 17.99L10.9 19.96C10.6 19.99 10.3 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10ZM16.23 14.26L18.5 13.5C18.96 13.35 18.95 12.69 18.49 12.55L10.89 10.27C10.51 10.16 10.15 10.51 10.27 10.89L12.55 18.49C12.69 18.96 13.35 18.97 13.5 18.5L14.26 16.23L18.17 20.14C18.37 20.34 18.68 20.34 18.88 20.14L20.15 18.87C20.35 18.67 20.35 18.36 20.15 18.16L16.23 14.26Z"
        fill="currentColor"
      />
    </svg>
  ),
};
export const copyIcon: Icon = {
  label: "copyIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        d="M4 0C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2V12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14H10C10.5304 14 11.0391 13.7893 11.4142 13.4142C11.7893 13.0391 12 12.5304 12 12V2C12 1.46957 11.7893 0.960859 11.4142 0.585786C11.0391 0.210714 10.5304 0 10 0H4ZM3 2C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H10C10.2652 1 10.5196 1.10536 10.7071 1.29289C10.8946 1.48043 11 1.73478 11 2V12C11 12.2652 10.8946 12.5196 10.7071 12.7071C10.5196 12.8946 10.2652 13 10 13H4C3.73478 13 3.48043 12.8946 3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12V2ZM0 4C8.91844e-06 3.64894 0.0924258 3.30406 0.267962 3.00003C0.443498 2.696 0.695969 2.44353 1 2.268V12.5C1 13.163 1.26339 13.7989 1.73223 14.2678C2.20107 14.7366 2.83696 15 3.5 15H9.732C9.55647 15.304 9.304 15.5565 8.99997 15.732C8.69594 15.9076 8.35106 16 8 16H3.5C2.57174 16 1.6815 15.6313 1.02513 14.9749C0.368749 14.3185 0 13.4283 0 12.5V4Z"
        fill="currentColor"
      />
    </svg>
  ),
};
export const errorlogIcon: Icon = {
  label: "errorlogIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
    >
      <path
        d="M5 5L9 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5 13L8 13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5 9L11 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M14.5167 16.8667C14.4018 16.8667 14.2916 16.821 14.2103 16.7397C14.129 16.6585 14.0834 16.5483 14.0834 16.4333V14.4333C14.0834 14.3184 14.129 14.2082 14.2103 14.1269C14.2916 14.0457 14.4018 14 14.5167 14C14.6316 14 14.7419 14.0457 14.8231 14.1269C14.9044 14.2082 14.95 14.3184 14.95 14.4333V16.4333C14.95 16.5483 14.9044 16.6585 14.8231 16.7397C14.7419 16.821 14.6316 16.8667 14.5167 16.8667Z"
        fill="currentColor"
      />
      <path
        d="M14.5 18.35C14.7761 18.35 15 18.1262 15 17.85C15 17.5739 14.7761 17.35 14.5 17.35C14.2239 17.35 14 17.5739 14 17.85C14 18.1262 14.2239 18.35 14.5 18.35Z"
        fill="currentColor"
      />
      <path
        d="M14.933 11.75L18.8301 18.5C19.0226 18.8333 18.782 19.25 18.3971 19.25H10.6029C10.218 19.25 9.97742 18.8333 10.1699 18.5L14.067 11.75C14.2594 11.4167 14.7406 11.4167 14.933 11.75Z"
        stroke="currentColor"
      />
    </svg>
  ),
};
export const progressIcon: Icon = {
  label: "progressIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        d="M17.5812 22H0V20.1667H1.91795C0.639316 15.5833 3.19658 13.75 4.87479 12.4667C5.59402 11.9167 6.31325 11.4583 6.31325 11C6.31325 10.5417 5.59402 9.99167 4.87479 9.53333C3.19658 8.25 0.639316 6.50833 1.91795 1.83333H0V0H17.5812V1.83333H15.6632C16.9419 6.41667 14.3846 8.25 12.7064 9.53333C11.9073 9.99167 11.188 10.5417 11.188 11C11.188 11.4583 11.9073 12.0083 12.6265 12.4667C14.3047 13.75 16.862 15.5833 15.5833 20.1667H17.5812V22ZM3.67607 20.1667H13.9051C15.1838 16.5 13.5056 15.3083 11.8274 14.025C10.7085 13.2917 9.58974 12.4667 9.58974 11C9.58974 9.53333 10.7085 8.70833 11.8274 7.975C13.5056 6.69167 15.1838 5.5 13.9051 1.83333H3.67607C2.39744 5.5 4.07564 6.69167 5.75385 7.975C6.87265 8.70833 7.99145 9.53333 7.99145 11C7.99145 12.4667 6.87265 13.2917 5.75385 14.025C4.07564 15.3083 2.39744 16.5 3.67607 20.1667Z"
        fill="currentColor"
      />
      <path
        d="M12.6265 21.0834H4.79492C4.55517 19.7084 4.95475 18.8834 6.63295 17.1417C7.27227 16.5001 8.07141 15.7667 8.79064 14.5751C9.58979 15.6751 10.3889 16.5001 10.9483 17.1417C12.6265 18.9751 13.0261 19.2501 12.6265 21.0834Z"
        fill="currentColor"
      />
      <path
        d="M6.71283 5.5C6.15342 6.69167 6.15343 6.69167 7.43206 7.425C7.83163 7.60833 8.31112 7.88333 8.71069 8.25C9.11026 7.88333 9.66966 7.60833 9.98932 7.425C11.3479 6.69167 11.3479 6.69167 10.7885 5.5"
        fill="currentColor"
      />
    </svg>
  ),
};
export const DashboardIcon: Icon = {
  label: "DashboardIcon",
  icon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="6.75"
        height="7.875"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="13.375"
        width="6.75"
        height="5.625"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="12.25"
        y="1"
        width="6.75"
        height="5.625"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="12.25"
        y="11.125"
        width="6.75"
        height="7.875"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
export const MasterIcon: Icon = {
  label: "MasterIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
    >
      <rect
        x="16"
        y="7"
        width="4"
        height="4"
        rx="2"
        transform="rotate(90 16 7)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="16"
        y="15"
        width="4"
        height="4"
        rx="2"
        transform="rotate(90 16 15)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="1"
        y="5"
        width="4"
        height="4"
        rx="2"
        transform="rotate(-90 1 5)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
};
export const ExperienceIcon: Icon = {
  label: "ExperienceIcon",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="29"
      viewBox="0 0 36 29"
      fill="none"
    >
      <path
        d="M16.1448 10.2061C13.4365 10.2061 11.2419 12.3533 11.2419 15.0021C11.2419 16.9193 12.3917 18.5738 14.0539 19.3414V17.5694C13.2833 16.968 12.7895 16.0415 12.7895 15.0021C12.7895 13.1893 14.2915 11.7208 16.1448 11.7208V10.2061Z"
        fill="currentColor"
      />
      <path
        d="M16.1448 10.205V10.2061V11.7208C16.7164 11.7208 17.2545 11.8605 17.7254 12.1069H20.0541C19.1587 10.9515 17.7406 10.205 16.1448 10.205Z"
        fill="currentColor"
      />
      <path
        d="M14.6778 25.8708C14.5446 25.7827 14.4423 25.656 14.3849 25.508L13.5333 23.2784C13.2427 23.1801 12.955 23.0737 12.6705 22.9594C12.4379 22.8597 12.2087 22.7522 11.9835 22.6371L9.87108 23.5303C9.7319 23.589 9.57845 23.6065 9.42942 23.5805C9.2804 23.5545 9.14221 23.4862 9.0317 23.384L7.46854 21.9331C7.35216 21.8255 7.27291 21.6845 7.24198 21.5299C7.21106 21.3754 7.23003 21.2151 7.29622 21.0718L8.20454 19.1138C8.08374 18.8819 7.97174 18.6456 7.86878 18.4054C7.7486 18.1114 7.63737 17.8139 7.53525 17.5133L5.54518 16.9138C5.38342 16.8655 5.24232 16.7656 5.14382 16.6298C5.04531 16.4939 4.9949 16.3297 5.00041 16.1626L5.07823 14.0495C5.08377 13.9116 5.1273 13.7779 5.20412 13.6627C5.28093 13.5475 5.3881 13.4553 5.51405 13.3961L5.75101 13.2835C5.9995 13.3586 6.67494 13.4862 7.3888 13.3961C8.10265 13.306 8.7892 13.0945 9.04324 13C9.0153 13.1072 8.93357 13.3281 8.86251 13.4136C8.79145 13.4992 8.70234 13.5683 8.60144 13.6161L6.61137 14.561L6.57134 15.639L8.37241 16.1802C8.61145 16.2517 8.80045 16.4332 8.87938 16.6663C9.05727 17.1943 9.19957 17.5837 9.30186 17.8279C9.43738 18.1378 9.58726 18.4414 9.75101 18.7376C9.80641 18.8395 9.83715 18.9528 9.84082 19.0685C9.84448 19.1841 9.82097 19.2991 9.77214 19.4042L8.93719 21.206L9.72767 21.9408L11.7088 21.1026C11.8156 21.0575 11.9311 21.0364 12.0471 21.041C12.163 21.0456 12.2765 21.0756 12.3792 21.129C12.785 21.3402 13.0874 21.4865 13.2787 21.5668C13.4732 21.6471 13.8457 21.7813 14.386 21.9639C14.4933 22.0001 14.5911 22.0593 14.6726 22.1373C14.7541 22.2152 14.8172 22.31 14.8574 22.4149V25.9587C14.7943 25.9373 14.7339 25.9079 14.6778 25.8708Z"
        fill="currentColor"
      />
      <path
        d="M18.0459 4.5027C17.9359 4.2013 17.6435 4 17.3166 4H14.9886C14.8293 3.99964 14.6737 4.04794 14.5432 4.1383C14.4127 4.22867 14.3135 4.35669 14.2592 4.5049L13.5255 6.52997C13.4736 6.54826 13.4232 6.56636 13.3743 6.5843L13.172 8.33974C13.2313 8.30538 13.2873 8.27447 13.3398 8.24705C13.5444 8.14035 13.8946 8.00285 14.3793 7.84336C14.4901 7.80703 14.5911 7.74627 14.6747 7.66566C14.7583 7.58506 14.8223 7.48671 14.8618 7.37806L15.5345 5.51468H16.7719L17.5134 7.55186C17.5583 7.67346 17.6336 7.78178 17.7324 7.86664C17.8312 7.9515 17.9502 8.0101 18.0782 8.03695C18.4007 8.10178 18.719 8.18628 19.031 8.28995C19.3969 8.42944 19.756 8.58581 20.1072 8.75854C20.2174 8.81078 20.3385 8.8367 20.4608 8.83422C20.583 8.83173 20.7029 8.80092 20.8109 8.74425L22.4652 7.87746L23.3146 8.74644L22.9116 9.68143H24.5931L24.9389 8.87954C25.059 8.60015 24.999 8.28005 24.7855 8.06115L23.1779 6.41667C23.0602 6.2969 22.9064 6.21811 22.7397 6.19213C22.5729 6.16614 22.4021 6.19437 22.2529 6.27257L20.4251 7.22956C20.0694 7.06567 19.7814 6.94577 19.558 6.86657C19.3534 6.79397 19.1121 6.72467 18.8309 6.65537L18.0459 4.5027Z"
        fill="currentColor"
      />
      <path
        d="M15.9722 18.877V26.4422C15.9722 26.9945 16.4199 27.4422 16.9722 27.4422H32.3859C32.9382 27.4422 33.3859 26.9945 33.3859 26.4422V18.877"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M23.1923 20.8888L15.6052 19.0613C15.1559 18.9531 14.8394 18.5512 14.8394 18.0891V14.0015C14.8394 13.4492 15.2871 13.0015 15.8394 13.0015H20.9625H33.5183C34.0705 13.0015 34.5183 13.4492 34.5183 14.0015V18.0838C34.5183 18.5483 34.1984 18.9517 33.7461 19.0575L25.8114 20.9146"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M20.998 13.2141V11.5596C20.998 11.0073 21.4458 10.5596 21.998 10.5596H27.3599C27.9122 10.5596 28.3599 11.0073 28.3599 11.5596V13.2141"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <rect
        x="23.6216"
        y="19.5186"
        width="1.8315"
        height="3.03488"
        rx="0.5"
        stroke="currentColor"
      />
      <path
        d="M6.95652 3V7.29565H3"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="6.5"
        cy="6.5"
        r="5.85"
        stroke="currentColor"
        strokeWidth="1.3"
      />
      <circle cx="6.89972" cy="7.29522" r="1.01739" fill="currentColor" />
    </svg>
  ),
};
export const Filter_Icon: Icon = {
  label: " Filter_Icon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path d="M26 3.44234C26 2.58752 26 2.16011 25.8281 1.83345C25.6789 1.54635 25.4404 1.31282 25.1469 1.16638C24.8125 1 24.375 1 23.5 1H3.5C2.625 1 2.1875 1 1.85313 1.16638C1.55913 1.31273 1.32011 1.54624 1.17031 1.83345C1 2.16011 1 2.58752 1 3.44234V4.56734C1 4.94133 1 5.12755 1.04375 5.3031C1.08201 5.45938 1.14529 5.60877 1.23125 5.74577C1.32656 5.89842 1.4625 6.03122 1.73125 6.2953L9.64219 14.0223C9.9125 14.2863 10.0484 14.4191 10.1438 14.5718C10.2297 14.7092 10.2938 14.8588 10.3313 15.0145C10.375 15.1885 10.375 15.3732 10.375 15.738V22.9978C10.375 24.306 10.375 24.9609 10.6563 25.3547C10.7783 25.525 10.9346 25.6692 11.1157 25.7784C11.2968 25.8876 11.4988 25.9595 11.7094 25.9897C12.1953 26.0599 12.7953 25.7684 13.9922 25.1822L15.2422 24.5716C15.7453 24.3274 15.9953 24.2053 16.1781 24.0221C16.3403 23.8603 16.4636 23.6653 16.5391 23.4512C16.625 23.21 16.625 22.9353 16.625 22.3873V15.7502C16.625 15.3762 16.625 15.19 16.6687 15.0145C16.707 14.8582 16.7702 14.7088 16.8562 14.5718C16.95 14.4191 17.0859 14.2879 17.3516 14.0284L17.3578 14.0223L25.2687 6.2953C25.5375 6.03122 25.6719 5.89842 25.7687 5.74577C25.8549 5.60885 25.9181 5.45942 25.9563 5.3031C26 5.13061 26 4.94438 26 4.57955V3.44234Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
};
export const Move_Icon: Icon = {
  label: "Move_Icon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
      <path d="M5 5L9 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M5 13L8 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M5 9L11 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round"/>
      <path d="M14.9191 19.1695L17.962 16.1276M17.962 16.1276L14.919 13.0894M17.962 16.1276L10.0549 16.1276" stroke="currentColor" strokeLinecap="round"/>
    </svg>
  ),
};
export const advancefilterIcon: Icon = {
  label: "advancefilterIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="39" viewBox="0 0 38 39" fill="none">
      <path d="M26 15.4423C26 14.5875 26 14.1601 25.8281 13.8334C25.6789 13.5463 25.4404 13.3128 25.1469 13.1664C24.8125 13 24.375 13 23.5 13H3.5C2.625 13 2.1875 13 1.85313 13.1664C1.55913 13.3127 1.32011 13.5462 1.17031 13.8334C1 14.1601 1 14.5875 1 15.4423V16.5673C1 16.9413 1 17.1276 1.04375 17.3031C1.08201 17.4594 1.14529 17.6088 1.23125 17.7458C1.32656 17.8984 1.4625 18.0312 1.73125 18.2953L9.64219 26.0223C9.9125 26.2863 10.0484 26.4191 10.1438 26.5718C10.2297 26.7092 10.2938 26.8588 10.3313 27.0145C10.375 27.1885 10.375 27.3732 10.375 27.738V34.9978C10.375 36.306 10.375 36.9609 10.6563 37.3547C10.7783 37.525 10.9346 37.6692 11.1157 37.7784C11.2968 37.8876 11.4988 37.9595 11.7094 37.9897C12.1953 38.0599 12.7953 37.7684 13.9922 37.1822L15.2422 36.5716C15.7453 36.3274 15.9953 36.2053 16.1781 36.0221C16.3403 35.8603 16.4636 35.6653 16.5391 35.4512C16.625 35.21 16.625 34.9353 16.625 34.3873V27.7502C16.625 27.3762 16.625 27.19 16.6687 27.0145C16.707 26.8582 16.7702 26.7088 16.8562 26.5718C16.95 26.4191 17.0859 26.2879 17.3516 26.0284L17.3578 26.0223L25.2687 18.2953C25.5375 18.0312 25.6719 17.8984 25.7687 17.7458C25.8549 17.6088 25.9181 17.4594 25.9563 17.3031C26 17.1306 26 16.9444 26 16.5796V15.4423Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.5 8.33333C25.7418 8.33333 25.0007 8.5777 24.3703 9.03554C23.7399 9.49338 23.2486 10.1441 22.9585 10.9055C22.8693 11.1394 22.8004 11.3806 22.752 11.6259H24.7602C24.8544 11.4043 24.9842 11.2013 25.1447 11.0269C25.4128 10.7355 25.7543 10.5371 26.1261 10.4567C26.4979 10.3763 26.8833 10.4176 27.2335 10.5753C27.5837 10.7329 27.883 11 28.0937 11.3426C28.3043 11.6852 28.4167 12.088 28.4167 12.5C28.4167 13.0525 28.2147 13.5824 27.8553 13.9731C27.7288 14.1106 27.5865 14.2269 27.433 14.3198V16.5414C27.6144 16.4919 27.7928 16.4279 27.967 16.3495C28.6674 16.0341 29.2661 15.5001 29.6873 14.8149C30.1085 14.1297 30.3333 13.3241 30.3333 12.5C30.3333 11.3949 29.9295 10.3351 29.2106 9.55372C28.4917 8.77232 27.5167 8.33333 26.5 8.33333Z" fill="currentColor"/>
      <path d="M34.3679 8.04792L35.7192 4.84375L33.4843 2.4125L30.3717 3.92083L29.168 3.30417L28.0372 0H24.8747L23.6557 3.45833L22.4041 3.94792L19.4467 2.46875L17.2118 4.90208L18.6052 8.29167L18.0398 9.6L15 10.8292V11.6259H17.708L19.4467 10.9229L20.4893 8.50625L19.2876 5.58958L20.0753 4.73125L22.5344 5.96042L24.8459 5.05625L25.8962 2.08333H27.0098L27.9509 4.83333L30.1704 5.96458L32.848 4.66875L33.6377 5.52708L32.5164 8.19375L33.3483 10.7021L36.0833 11.8437V13.0542L33.5533 14.075L32.5126 16.4917L33.7067 19.4042L32.9208 20.2646L30.4675 19.0396L28.1541 19.9438L27.1038 22.9167H25.9902L25.1451 20.4471L23.832 21.6958L24.9628 25H28.1253L29.3443 21.5417L30.5959 21.0521L33.5533 22.5313L35.7805 20.0917L34.3928 16.7083L34.9602 15.4L38 14.1708V10.7333L34.8183 9.40834L34.3679 8.04792Z" fill="currentColor"/>
      <path d="M3 3L3 8" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round"/>
      <path d="M13 3L13 5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round"/>
      <path d="M14 8L14 10" stroke="currentColor" strokeLinecap="round"/>
      <circle cx="8" cy="7" r="1.5" stroke="currentColor"/>
      <circle cx="18" cy="3" r="1.5" stroke="currentColor"/>
    </svg>
  ),
};
export const eyefillIcon: Icon = {
  label: "eyefillIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
      <path d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36428 13 3.23207 9.78986 1.81198 8.06571C1.42382 7.59444 1.22974 7.35881 1.22974 7C1.22974 6.64119 1.42382 6.40556 1.81198 5.93429C3.23207 4.21014 6.36428 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z" fill="#312651"/>
      <path d="M10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10Z" fill="white"/>
    </svg>
  ),
};
export const rightaerrowIcon: Icon = {
  label: "rightaerrowIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <path d="M10 1L16 7M16 7L10 13M16 7H1" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
  ),
};
export const leftaerrowIcon: Icon = {
  label: "leftaerrowIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
  ),
};
export const allquestionIcon: Icon = {
  label: "allquestionIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
      <path d="M10.6667 21.3333C11.3739 21.3333 12.0522 21.0524 12.5523 20.5523C13.0524 20.0522 13.3333 19.3739 13.3333 18.6667L13.3333 5.33334C13.3333 4.6261 13.0524 3.94782 12.5523 3.44773C12.0522 2.94763 11.3739 2.66668 10.6667 2.66668L2.66667 2.66668C1.95942 2.66668 1.28115 2.94763 0.781049 3.44773C0.280952 3.94782 -4.76837e-07 4.6261 -4.76837e-07 5.33334L-4.76837e-07 18.6667C-4.76837e-07 19.3739 0.280952 20.0522 0.781049 20.5523C1.28115 21.0524 1.95942 21.3333 2.66667 21.3333L10.6667 21.3333ZM12 18.6667C12 19.0203 11.8595 19.3594 11.6095 19.6095C11.3594 19.8595 11.0203 20 10.6667 20L2.66667 20C2.31304 20 1.97391 19.8595 1.72386 19.6095C1.47381 19.3594 1.33333 19.0203 1.33333 18.6667L1.33333 5.33334C1.33333 4.97972 1.47381 4.64058 1.72386 4.39053C1.97391 4.14049 2.31304 4.00001 2.66667 4.00001L10.6667 4.00001C11.0203 4.00001 11.3594 4.14049 11.6095 4.39053C11.8595 4.64058 12 4.97972 12 5.33334L12 18.6667ZM16 16C16 16.4681 15.8768 16.9279 15.6427 17.3333C15.4087 17.7387 15.072 18.0753 14.6667 18.3093L14.6667 4.66668C14.6667 3.78262 14.3155 2.93477 13.6904 2.30965C13.0652 1.68453 12.2174 1.33334 11.3333 1.33334L3.024 1.33334C3.25804 0.927968 3.59467 0.59134 4.00004 0.357292C4.40541 0.123243 4.86525 2.1428e-05 5.33333 9.53674e-06L11.3333 9.53674e-06C12.571 9.53674e-06 13.758 0.491674 14.6332 1.36684C15.5083 2.24201 16 3.429 16 4.66668L16 16Z" fill="currentColor"/>
      <path d="M5 10C5 9.60444 5.1173 9.21776 5.33706 8.88886C5.55682 8.55996 5.86918 8.30362 6.23463 8.15224C6.60009 8.00087 7.00222 7.96126 7.39018 8.03843C7.77814 8.1156 8.13451 8.30608 8.41421 8.58579C8.69392 8.86549 8.8844 9.22186 8.96157 9.60982C9.03874 9.99778 8.99913 10.3999 8.84776 10.7654C8.69638 11.1308 8.44004 11.4432 8.11114 11.6629C7.78224 11.8827 7.39556 12 7 12V13.3333" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 15.3333C6.80222 15.3333 6.60888 15.392 6.44443 15.5019C6.27998 15.6118 6.15181 15.7679 6.07612 15.9507C6.00043 16.1334 5.98063 16.3345 6.01922 16.5284C6.0578 16.7224 6.15304 16.9006 6.29289 17.0404C6.43275 17.1803 6.61093 17.2755 6.80491 17.3141C6.99889 17.3527 7.19996 17.3329 7.38268 17.2572C7.56541 17.1815 7.72159 17.0534 7.83147 16.8889C7.94135 16.7245 8 16.5311 8 16.3333C7.99655 16.0692 7.89008 15.8168 7.70329 15.6301C7.5165 15.4433 7.26414 15.3368 7 15.3333Z" fill="currentColor"/>
    </svg>
  ),
};
export const reviewIcon: Icon = {
  label: "reviewIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path d="M4 5L8 5" stroke="currentColor" stroke-width="1.3" stroke-linecap="round"/>
      <path d="M4 13L6 13" stroke="currentColor" stroke-width="1.3" stroke-linecap="round"/>
      <path d="M4 9L9 9" stroke="currentColor" stroke-width="1.3" stroke-linecap="round"/>
      <path d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8" stroke="currentColor" stroke-width="1.3" stroke-linecap="round"/>
      <circle cx="12.3333" cy="14.3333" r="3.33333" stroke="currentColor" stroke-linecap="round"/>
      <path d="M17 19L15 17" stroke="currentColor" stroke-linecap="round"/>
      <path d="M11.0918 14.4584L11.8547 15.1807C11.9327 15.2545 12.0551 15.2536 12.1319 15.1785L14.0101 13.3445" stroke="currentColor" stroke-width="0.9" stroke-linecap="round"/>
    </svg>
  ),
};
export const dateIcon: Icon = {
  label: "dateIcon",
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g clip-path="url(#clip0_1107_6)">
        <path d="M21.1036 24.8215H3.89641C2.91196 24.8194 1.96843 24.4274 1.27231 23.7313C0.576197 23.0352 0.184205 22.0916 0.182129 21.1072V6.11431C0.184019 5.12918 0.576442 4.185 1.27337 3.48874C1.97029 2.79248 2.91486 2.40097 3.89999 2.40002H21.1071C22.0923 2.40191 23.0364 2.79434 23.7327 3.49126C24.429 4.18819 24.8205 5.13275 24.8214 6.11788V21.1036C24.8203 22.0893 24.4282 23.0343 23.7312 23.7313C23.0342 24.4283 22.0892 24.8203 21.1036 24.8215ZM3.89641 4.18217C3.38415 4.18273 2.89303 4.38648 2.53081 4.74871C2.16859 5.11093 1.96484 5.60205 1.96427 6.11431V21.1036C1.96597 21.6152 2.17019 22.1053 2.53228 22.4667C2.89436 22.8281 3.38483 23.0314 3.89641 23.0322H21.1036C21.6146 23.0307 22.1042 22.827 22.4656 22.4656C22.827 22.1043 23.0306 21.6146 23.0321 21.1036V6.11431C23.0308 5.60323 22.8272 5.11345 22.4658 4.75206C22.1044 4.39067 21.6146 4.18706 21.1036 4.18574L3.89641 4.18217Z" fill="currentColor"/>
        <path d="M23.9286 12.3793H1.10074C0.863942 12.3793 0.636841 12.2852 0.469398 12.1178C0.301954 11.9503 0.207886 11.7232 0.207886 11.4864C0.207886 11.2496 0.301954 11.0225 0.469398 10.8551C0.636841 10.6876 0.863942 10.5936 1.10074 10.5936H23.9286C24.1654 10.5936 24.3925 10.6876 24.5599 10.8551C24.7274 11.0225 24.8215 11.2496 24.8215 11.4864C24.8215 11.7232 24.7274 11.9503 24.5599 12.1178C24.3925 12.2852 24.1654 12.3793 23.9286 12.3793Z" fill="currentColor"/>
        <path d="M6.75638 6.5393C6.6391 6.5394 6.52296 6.51637 6.41459 6.47153C6.30622 6.42669 6.20776 6.36093 6.12483 6.278C6.0419 6.19507 5.97614 6.09661 5.9313 5.98824C5.88646 5.87987 5.86343 5.76372 5.86353 5.64645V1.07145C5.86353 0.834646 5.95759 0.607544 6.12504 0.440101C6.29248 0.272657 6.51958 0.178589 6.75638 0.178589C6.99318 0.178589 7.22029 0.272657 7.38773 0.440101C7.55517 0.607544 7.64924 0.834646 7.64924 1.07145V5.64645C7.64924 5.88325 7.55517 6.11035 7.38773 6.27779C7.22029 6.44523 6.99318 6.5393 6.75638 6.5393Z" fill="currentColor"/>
        <path d="M18.2443 6.5393C18.127 6.5394 18.0109 6.51637 17.9025 6.47153C17.7941 6.42669 17.6957 6.36093 17.6127 6.278C17.5298 6.19507 17.4641 6.09661 17.4192 5.98824C17.3744 5.87987 17.3513 5.76372 17.3514 5.64645V1.07145C17.3514 0.834646 17.4455 0.607544 17.613 0.440101C17.7804 0.272657 18.0075 0.178589 18.2443 0.178589C18.4811 0.178589 18.7082 0.272657 18.8756 0.440101C19.0431 0.607544 19.1372 0.834646 19.1372 1.07145V5.64645C19.1372 5.88325 19.0431 6.11035 18.8756 6.27779C18.7082 6.44523 18.4811 6.5393 18.2443 6.5393Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_1107_6">
          <rect width="25" height="25" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  ),
};