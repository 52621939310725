import { Button, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { confirmBox } from "../toast";
import { edit } from "../../constant/icons";
import { useState } from "react";

const EditControl = (props: any) => {
    const { disabled, column, rowData, value, message } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
      };
    return(
        <>
            <Button color="link" className="text-success" onClick={()=>column.callBack(rowData)} id="editTooltip">{edit.icon}</Button>
            <UncontrolledTooltip isOpen={tooltipOpen} target="editTooltip" toggle={toggleTooltip}>
            Edit
            </UncontrolledTooltip>
        </>
    );
};

export { EditControl };