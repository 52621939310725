import { Button, Col, FormGroup, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { arrowLeft } from "../../constant/icons";
import ReactSelect from "react-select";
import PageLayout from "../layout/page";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { __t } from "../../i18n/translator";

const AddUpdateUser = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  const roleList = api.GetValue("/admin/roles");
  const validator = useRef(new SimpleReactValidator());
  const ListOfRole = roleList?.data?.list
    ?.filter(
      (item: any) =>
        item.roleName !== "Candidate" && item.roleName !== "Super Admin"
    )
    ?.map((item: any) => {
      return {
        value: item.roleName,
        label: item.roleName,
      };
    });

  const initialValues: {
    firstName: string;
    lastName: string;
    email: string;
    loginType: string;
    socialId: string;
    roleName: string;
    profileImg: string;
    birthDate: string;
    gender: string;
    mobileNumber: string;

  } = {
    firstName: value?.firstName ? value.firstName : "",
    lastName: value?.lastName ? value.lastName : "",
    email: value?.email ? value.email : "",
    loginType: value?.loginType ? value.loginType : "LC",
    socialId: value?.socialId ? value.socialId : "gmail",
    roleName: value?.roleName ? value.roleName : "",
    profileImg: value?.profileImg ? value.profileImg : "",
    birthDate: value?.birthDate ? value.birthDate : "01/01/2000",
    gender: value?.gender ? value.gender : "Male",
    mobileNumber: value?.mobileNumber ? value.mobileNumber : "",

  };
  const handleCreateUser = async (values: any, actions: any) => {
    if (validator.current.allValid()) {
      if (value.id) {
        api.updateValue({ ...values, id: value.id }).then((result: any) => {
          if (result?.status) {
            closeAddUpdate();
          }
        });
      } else {
        api.create({ ...initialValues, ...values }).then((result: any) => {
          if (result?.status) {
            closeAddUpdate();
          }
        });
      }
    } else {
      validator.current.showMessages();
    }
  };
  return (
    <PageLayout
      title={
        <>
          <Button
            color="link"
            className="btn-back"
            onClick={() => props.closeAddUpdate()}
          >
            {arrowLeft.icon}{" "}
          </Button>
          {value.id ? "Edit" : "Add"} User
        </>
      }
      View={
        <Formik initialValues={initialValues} onSubmit={handleCreateUser}>

          {({ values, setFieldValue }) => (
            <Form className="form-row">
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
                <Label htmlFor="firstName">
                  First Name<sup className="text-danger">*</sup>
                </Label>
                {validator.current.message(
                  __t("validations.user.firstName"),
                  values.firstName,
                  "required",
                  {
                    messages: {
                      required: __t("validations.user.firstName"),
                    },
                  }
                )}
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
                <Label htmlFor="lastName">
                  Last Name<sup className="text-danger">*</sup>
                </Label>
                {validator.current.message(
                  __t("validations.user.lastName"),
                  values.lastName,
                  "required",
                  {
                    messages: {
                      required: __t("validations.user.lastName"),
                    },
                  }
                )}
              </FormGroup>
              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
                <Label htmlFor="email">
                  Email<sup className="text-danger">*</sup>
                </Label>
                {validator.current.message(
                  __t("validations.user.email"),
                  values.email,
                  "required",
                  {
                    messages: {
                      required: __t("validations.user.email"),
                    },
                  }
                )}
              </FormGroup>

              <FormGroup className="col-4 form-floating mb-0">
                <ReactSelect
                  name="roleName"
                  onChange={(newValue) => {
                    setFieldValue("roleName", newValue?.value);
                  }}
                  placeholder={
                    <Label className="mb-0" htmlFor="'roleName'">
                      Select Role<sup className="text-danger fs-6">*</sup>
                    </Label>
                  }
                  options={ListOfRole}
                  value={ListOfRole?.find(
                    (x: any) => x.value === values.roleName
                  )}
                />
                {validator.current.message(
                  __t("validations.user.roleId"),
                  values.roleName,
                  "required",
                  {
                    messages: {
                      required: __t("validations.user.roleId"),
                    },
                  }
                )}
              </FormGroup>

              <FormGroup className="col-4 form-floating mb-0">
                <Field
                  className="form-control"
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  maxLength={10}
                  minLength={10}
                />
                <Label htmlFor="mobileNumber">
                  Mobile Number<sup className="text-danger">*</sup>
                </Label>

                {validator.current.message(
                  __t("validations.user.mobileNumber"),
                  values.mobileNumber,
                  "required|matches:^[0-9]{10}$|numeric",
                  {
                    messages: {
                      required: __t("validations.user.mobileNumber"),
                      matches: __t("validations.user.matches"),
                    },
                  })}

              </FormGroup>
              <Col lg="12" className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {value.id ? "Update" : "Add"}
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
export { AddUpdateUser };
