import { DataType } from "ka-table/enums";
import { TableColumn } from "../../components/table/table";
import { StatusControl } from "../../components/buttons/status";
import { EditControl } from "../../components/buttons/edit";

export const columns = (actions: any): TableColumn[] => [
  {
    key: "id",
    title: "id",
    dataType: DataType.String,
    visible: false,
  },
  {
    key: "firstName",
    title: "User Name",
    dataType: DataType.String,
    valueConvert: (row: any, rowData: any) => {
      return rowData?.firstName + " " + rowData?.lastName;
    },
  },

  {
    key: "roleName",
    title: "Role",
    dataType: DataType.String,
    valueConvert: (row: any, rowData: any) => {
      return rowData?.role?.roleName;
    },
  },
  {
    key: "email",
    title: "Email Id",
    dataType: DataType.String,
  },
  {
    key: "mobileNumber",
    title: "Mobile No",
    dataType: DataType.String,
  },
  {
    key: "isActive",
    title: "Status",
    dataType: DataType.Boolean,
    component: StatusControl,
    callBack: (row) => {
      actions.api.status({
        id: row.id,
        isActive: !row.isActive,
      });
    },
    style: {
      textAlign: "center",
    },
  },
  {
    key: "edit",
    title: "",
    component: EditControl,
    callBack: (row) => {
      actions.openAddEditFrom(row);
    },
    style: {
      textAlign: "center",
    },
  },
];
