import React from "react";
import { FilterProvider } from "../../helper/filterHelper";
import QueFilter from "../questionAi/filter";
import { Outlet } from "react-router-dom";

export default function QuestionLayout() {
  return (
    <>
      <FilterProvider>
        <div className="inner-page">
          <div className="section-head">
            <div>
              <h4>{"Question List"}</h4>
            </div>
            <div className="header-action-item"></div>
          </div>

          <div className="filter-section mb-3">
            {" "}
            <QueFilter

            // api={api}
            />
          </div>
          <Outlet />
        </div>
      </FilterProvider>
    </>
  );
}
