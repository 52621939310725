import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginFrom } from "../modules/auth/type";
import { get, post } from "../helper/api";
import { DANGER, SUCCESS } from "../constant/commonConstants";
import { ApiResponseMessage } from "../helper/tosterHelper";
import { useLoaderHelper } from "../helper/loaderHelper";

interface currentUser {
  firstName: string;
  lastName: string;
  token: string;
  roleName: string;
}
interface AuthContextValue {
  user: currentUser | null;
  login: (loginToke: LoginFrom) => void;
  logOut: () => void;
  loader: any;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: () => {},
  logOut: () => {},
  loader: false,
});

const getLoginUser = (userOb: any): currentUser | null => {
  try {
    if (!userOb) {
      return null;
    }

    return {
      firstName: userOb.firstName,
      lastName: userOb.lastName,
      token: userOb.userToken,
      roleName: userOb.roleName,
    } as currentUser;
  } catch {}
  return null;
};
export const getToken = (): string => {
  try {
    const token = localStorage.getItem("token");
    if (token && JSON.parse(token)) {
      return JSON.parse(token) as string;
    }
  } catch {
    debugger;
  }
  return "";
};
export const AuthProvider = ({ children }: any) => {
  // const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<currentUser | null>(
    getLoginUser({
      lastName: localStorage.getItem("lastName")
        ? JSON.parse(localStorage.getItem("lastName")!)
        : null,
      firstName: localStorage.getItem("firstName")
        ? JSON.parse(localStorage.getItem("firstName")!)
        : null,
      token: localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null,
      roleName: localStorage.getItem("roleName")
        ? JSON.parse(localStorage.getItem("roleName")!)
        : null,
    })
  );
  const navigate = useNavigate();
  const loader = useLoaderHelper();

  const login = async (loginFrom: LoginFrom) => {
    // setLoading(true);
    const loginToke = await post<LoginFrom>(
      "/admin/login",
      loginFrom,
      true,
      loader
    );
    if (loginToke?.status && loginToke.data.userToken) {
      localStorage.setItem("token", JSON.stringify(loginToke.data.userToken));
      localStorage.setItem(
        "firstName",
        JSON.stringify(loginToke.data.firstName)
      );
      localStorage.setItem("lastName", JSON.stringify(loginToke.data.lastName));
      localStorage.setItem(
        "roleName",
        JSON.stringify(loginToke.data.roleName)
      );
      setUser(getLoginUser(loginToke.data));
      navigate("/dashboard");
      ApiResponseMessage(
        loginToke.message,
        loginToke?.status ? SUCCESS : DANGER
      );
      loader.setDataIsLoading(false);

      // setLoading(false);
    } else {
      // setLoading(false);
    }
  };

  const logOut = () => {
    localStorage.clear();
    setUser(null);
    navigate("/");
  };

  const forgotPassword = () => {};
  const resetPassword = () => {};

  return (
    <AuthContext.Provider value={{ user, login, logOut, loader }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
