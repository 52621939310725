import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import { Nav, NavItem, NavLink } from "reactstrap";
import { AccordionTable } from "../../components/table/accordionTable";
import { useFilterHelper } from "../../helper/filterHelper";
import { formatDateToYYYYMMDD } from "../../helper/utility";

export const QuestionAPI = (url: string, data: any) => {
  return { ...useApi(url, false, data, {}, false, true) };
};
interface paginationType {
  limit?: number;
  page?: number;
}
interface searchType {
  searchText: string;
}
function QuestionAi(props: any) {
  const {
    topicIds,
    experience,
    selectedKey,
    selectedValue,
    selectedSkill,
    selectedIndustry,
    selectedProfession,
    questionType,
  } = props;
  const [tabSelect, setTabSelect] = useState("");
  const [total, setTotal] = useState(0);
  useEffect(() => {
    setTabSelect("Low");
  }, [experience]);

  const tabColor = tabSelect.toLowerCase();
  return (
    <div className="company_form question_tab">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={tabSelect === "Low" ? "active" : " "}
            onClick={() => {
              setTabSelect("Low");
            }}
          >
            Low
            {total > 0 && tabSelect === "Low" && (
              <span className="total_que">{total}</span>
            )}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabSelect === "Medium" ? "active" : " "}
            onClick={() => {
              setTabSelect("Medium");
            }}
          >
            Medium
            {total > 0 && tabSelect === "Medium" && (
              <span className="total_que">{total}</span>
            )}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabSelect === "High" ? "active" : " "}
            onClick={() => {
              setTabSelect("High");
            }}
          >
            High
            {total > 0 && tabSelect === "High" && (
              <span className="total_que">{total}</span>
            )}
          </NavLink>
        </NavItem>
      </Nav>
      <div className={`question_tab_contant ${tabColor ? tabColor : ""}`}>
        {tabSelect && (
          <QuestionTab
            topicIds={topicIds}
            experience={experience}
            selectedKey={selectedKey}
            selectedValue={selectedValue}
            selectedSkill={selectedSkill}
            selectedIndustry={selectedIndustry}
            selectedProfession={selectedProfession}
            questionType={questionType}
            tabSelect={tabSelect}
            setTotal={setTotal}
          />
        )}
      </div>
    </div>
  );
}
const QuestionTab = (props: any) => {
  const {
    topicIds,
    experience,
    selectedKey,
    selectedValue,
    selectedSkill,
    selectedIndustry,
    selectedProfession,
    questionType,
    tabSelect,
    setTotal,
  } = props;
  const { selectedDates } = useFilterHelper();
  const [pagination, setPagination] = useState<paginationType>({
    page: 0,
    limit: 10,
  });
  const [searchData, setSearchData] = useState<searchType>({
    searchText: "",
  });

  const bodyResponse = {
    countryId: selectedValue,
    industryId: selectedIndustry,
    professionId: selectedProfession,
    skillId: selectedSkill,
    keyConceptId: selectedKey,
    topicId: topicIds,
    experienceId: experience,
    complexity: tabSelect,
    type: questionType,
    fromDate: selectedDates[0] ? formatDateToYYYYMMDD(selectedDates[0]) : "",
    toDate: selectedDates[1] ? formatDateToYYYYMMDD(selectedDates[1]) : "",
    ...pagination,
    ...searchData,
  };
  useEffect(() => {
    if (tabSelect) {
      setPagination({
        page: 0,
        limit: 10,
      });
    }
  }, [experience, tabSelect]);
  useEffect(() => {
    setPagination({
      ...pagination,
      page: 0,
    });
  }, [pagination.limit])
  let api: any = [];
  if (questionType === "All") {
    api = QuestionAPI("questionAi/question", bodyResponse);
  } else {
    api = QuestionAPI("admin/question", bodyResponse);
  }

  useEffect(() => {
    api.mutate();
  }, []);

  useEffect(() => {
    if (api?.data?.totalRecords) {
      setTotal(api.data.totalRecords);
    } else {
      setTotal(0);
    }
  }, [api?.data]);
  return (
    <AccordionTable
      columns={columns({
        api,
        questionType,
      })}
      rows={api?.data}
      updatePagination={{
        searchData,
        setSearchData,
        pagination,
        setPagination,
      }}
    />
  );
};
export { QuestionAi, QuestionTab };
