import { useApi } from "../../helper/apiHook";
import { useState, useEffect } from "react";
import { useApp } from "../../components/app";
import { AccodianView } from "./questionlist";
import Nodata_found from "../../assets/images/no_data_found.png";
import { useFilterHelper } from "../../helper/filterHelper";
// export const TopicAPI = (data: any) => {
//   return { ...useApi("admin/topic", true, data, {}, false, true) };
// };
function TopicWiseQuestionList(props: any) {
  const app = useApp();
  const {
    selectedValue,
    selectedIndustry,
    selectedKey,
    selectedSkill,
    setSelectedTopic,
    setSelectedCity,
    selectedJob,
    setSelectedJob,
    selectedDesignation,
    selectedProfession,
    topicIds,
    setTopicIds,
  } = useFilterHelper();
  const isSelectedFilter =
    selectedValue.length > 0 &&
    selectedIndustry.length > 0 &&
    selectedProfession.length > 0 &&
    selectedSkill.length > 0
    //  &&
    // selectedKey.length > 0;
  useEffect(() => {
    app.updateConversation("Question");
  }, []);
  return (
    <>
      {isSelectedFilter ? (
        <AccodianView
          filterBody={{
            countryId: selectedValue,
            industryId: selectedIndustry,
            professionId: selectedProfession,
            skillId: selectedSkill,
            keyConceptId: selectedKey,
            jobId: selectedJob,
            designationId: selectedDesignation,
            isActive: true,
          }}
          setTopicIds={setTopicIds}
          topicIds={topicIds}
          selectedKey={selectedKey}
          selectedSkill={selectedSkill}
          // api={api}
          selectedValue={selectedValue}
          setSelectedCity={setSelectedCity}
          selectedProfession={selectedProfession}
          setSelectedJob={setSelectedJob}
          setSelectedTopic={setSelectedTopic}
          selectedIndustry={selectedIndustry}
        />
      ) : (
        <div className="no_data_found_img question_notfound">
          <img src={Nodata_found} alt="nodata_found" />
        </div>
      )}
    </>
  );
}

export default TopicWiseQuestionList;
