import { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  NavbarBrand,
} from "reactstrap";
import { hamburgerMenu, logoutDuotone } from "../../constant/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../components/auth";
import { useApp } from "../../components/app";
import { getInitialsFirstCharechter, getValueBykey } from "../../utils/generalUtils";

export const Header = (props: any) => {
  const { handalToggaleSidbar } = props;
  const auth = useAuth();
  const app = useApp();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <header className="header">
      <div className="left-panel">
        <Button
          color="link"
          onClick={handalToggaleSidbar}
          title="Collapse sidebar"
          className="hamburger-btn"
        >
          {hamburgerMenu.icon}
        </Button>
        <h5>{app.conversation || "Dashboard"}</h5>
      </div>
      <div className="right-panel">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="profile-main"
        >
          <DropdownToggle color="link">
            <span>
              {getInitialsFirstCharechter(
                auth.user?.firstName,
                auth.user?.lastName
              )}
            </span>
            
            <h6>
              {auth.user?.firstName}&nbsp;{auth.user?.lastName}
              <small>{JSON.parse(getValueBykey("roleName")!)}</small>
            </h6>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => auth.logOut()}>
              <span>{logoutDuotone.icon}</span>
              <p>
                Log Out
                <small></small>
              </p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </header>
  );
};
