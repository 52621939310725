import React, { createContext, useContext, useState, ReactNode } from "react";

// Define types for your filter state
interface FilterState {
  selectedValue: any[];
  setSelectedValue: React.Dispatch<React.SetStateAction<any[]>>;
  selectedIndustry: any[];
  setSelectedIndustry: React.Dispatch<React.SetStateAction<any[]>>;
  selectedKey: any[];
  setSelectedKey: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSkill: any[];
  setSelectedSkill: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTopic: any[];
  setSelectedTopic: React.Dispatch<React.SetStateAction<any[]>>;
  selectedCity: any[];
  setSelectedCity: React.Dispatch<React.SetStateAction<any[]>>;
  selectedJob: any[];
  setSelectedJob: React.Dispatch<React.SetStateAction<any[]>>;
  selectedDesignation: any[];
  setSelectedDesignation: React.Dispatch<React.SetStateAction<any[]>>;
  selectedProfession: any[];
  setSelectedProfession: React.Dispatch<React.SetStateAction<any[]>>;
  topicIds: string[];
  setTopicIds: React.Dispatch<React.SetStateAction<string[]>>;
  status: null;
  setStatus: React.Dispatch<React.SetStateAction<null>>;
  questionType: any;
  setQuestionType: any;
  selectedDates: Date[];
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
  // other props...
}

// Create a context
const FilterContext = createContext<FilterState | undefined>(undefined);
// Create a provider component
interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState<any[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any[]>([]);
  const [selectedKey, setSelectedKey] = useState<any[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<any[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any[]>([]);
  const [selectedJob, setSelectedJob] = useState<any[]>([]);
  const [selectedDesignation, setSelectedDesignation] = useState<any[]>([]);
  const [selectedProfession, setSelectedProfession] = useState<any[]>([]);
  const [topicIds, setTopicIds] = useState<string[]>([]);
  const [status, setStatus] = useState<null>(null);
  const [questionType, setQuestionType] = useState<string>(selectedKey?.length === 0 ? "key" : "All");
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const state: FilterState = {
    selectedValue,
    setSelectedValue,
    selectedIndustry,
    setSelectedIndustry,
    selectedKey,
    setSelectedKey,
    selectedSkill,
    setSelectedSkill,
    selectedTopic,
    setSelectedTopic,
    selectedCity,
    setSelectedCity,
    selectedJob,
    setSelectedJob,
    selectedDesignation,
    setSelectedDesignation,
    selectedProfession,
    setSelectedProfession,
    topicIds,
    setTopicIds,
    status,
    setStatus,
    questionType,
    setQuestionType,
    selectedDates,
    setSelectedDates
  };
  return (
    <FilterContext.Provider value={state}>{children}</FilterContext.Provider>
  );
};

// Create a custom hook to use the filter state
export const useFilterHelper = (): FilterState => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterHelper must be used within a FilterProvider");
  }
  return context;
};
