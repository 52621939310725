import { Table, useTable } from "ka-table";
import { useState, useEffect } from "react";
import { ActionType, PagingPosition, SortingMode } from "ka-table/enums";
import "./style.scss";
import { ChildComponents, Column } from "ka-table/models";
import { FormGroup, Input } from "reactstrap";
import { updatePageSize } from "ka-table/actionCreators";
import PagingPages from "./pagingPages";
export declare class TableColumn extends Column {
  component?: any;
  valueConvert?: any;
  callBack?: (rowValue: any) => void;
}
export function generateUniqueId() {
  const timestamp = new Date().getTime(); // Get the current timestamp
  const random = Math.random() * 1000000; // Generate a random number
  const uniqueId = `${timestamp}${random}`;
  return `Table${uniqueId}`;
}
const GetComponents = (
  columnsList?: TableColumn[]
): ChildComponents | undefined => {
  if (columnsList && columnsList.length > 0) {
    const componentList = columnsList.filter(
      (x) => x.component || x.valueConvert
    );
    if (componentList && componentList.length > 0) {
      return {
        cellText: {
          content: (props) => {
            const columnFind = componentList.filter(
              (x) => x.key === props.column.key
            )[0];
            if (columnFind) {
              if (columnFind.component) {
                return (
                  <>
                    {" "}
                    <columnFind.component {...props} />
                  </>
                );
              } else if (columnFind.valueConvert) {
                const value = columnFind.valueConvert(
                  props.rowData[props.column.key],
                  props.rowData
                );
                return value;
              }
            }
          },
        },
      };
    }
  }
  return undefined;
};

let tableComponents: ChildComponents = {
  table: {
    elementAttributes: () => ({
      className: "table table-striped table-hover",
    }),
  },

  noDataRow: {
    content: () => "No Data Found",
  },
  pagingIndex: {
    elementAttributes: ({ isActive }) => ({
      className: `page-item ${isActive ? "active" : ""}`,
    }),
    content: ({ text, isActive }) => (
      <button className={`page-link ${isActive ? "active" : ""}`}>
        {text}
      </button>
    ),
  },
  pagingPages: {
    elementAttributes: () => ({
      className: "pagination",
    }),
    content(props) {
      return (
        <>
          {" "}
          <PagingPages {...props} />
        </>
      );
    },
  },

  pagingSizes: {
    content: (props) => {
      return (
        <>
          <FormGroup>
            <Input
              type="select"
              value={
                props.pageSize
                // s?.map((size) => size === )
              }
              onChange={(e) => {
                props.dispatch(updatePageSize(Number(e.target.value)));
              }}
            >
              {props.pageSizes?.map((size, index) => (
                <option key={index}>{size}</option>
              ))}
            </Input>
          </FormGroup>

          <></>
        </>
      );
    },
  },
};

const DataView = (props: any) => {
  const { columns, rows } = props;
  const components = GetComponents(columns);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const paging = props.updatePagination
    ? {
        paging: {
          enabled: true,
          pageIndex: rows?.currentPage ? rows?.currentPage - 1 : 0,
          pagesCount: rows?.totalPages ? rows.totalPages : 1,
          pageSizes: [10, 20, 50, 100],
          pageSize: selectedPageSize,
          position: PagingPosition.Bottom,
        },
      }
    : {};
  const totaldata = rows?.totalRecords ? rows.totalRecords : null;
  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.UpdatePageIndex) {
        props.updatePagination()?.updateCurrentPageNo(action.pageIndex);
      } else if (action.type === ActionType.UpdatePageSize) {
        props.updatePagination()?.updatePageLimit(action.pageSize);
        setSelectedPageSize(action.pageSize);
      }
    },
  });

  if (components) {
    tableComponents = { ...tableComponents, ...components };
  }
  return (
    <div className="data-table data-table-wrapper">
      <Table
        key={generateUniqueId()}
        table={table}
        {...(rows?.list?.length > 0 && { ...paging })}
        childComponents={tableComponents}
        columns={columns}
        data={rows?.list}
        rowKeyField={"id"}
        sortingMode={SortingMode.Single}
      />
      {totaldata && (
        <p className="total_count">
          Total Data: <span>{totaldata}</span>
        </p>
      )}
    </div>
  );
};
export { DataView };
