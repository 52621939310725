import { Button, Card, CardBody, Col } from "reactstrap";
import { handleCopyClick } from "./common";
import { copyToClipboard } from "../../constant/icons";

export const EmailPlaceHolders = (props: any) => {
  const { EmailFields } = props;
  return (
    <Col md="3" className="Default_notifications">
      <Card>
        <div className="card-header">
          <h4 className="card-title">Insert email placeholders</h4>
        </div>

        <CardBody className="insert_placeholder">
          <ul>
            {EmailFields?.map((item: any, index: number) => (
              <li key={index}>
                <span>{item?.customName}</span>
                <Button
                  color="primary"
                  onClick={() => {
                    handleCopyClick(item?.customName);
                  }}
                >
                  {copyToClipboard.icon}
                </Button>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </Col>
  );
};
